import React, { useContext, useEffect, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import './tire.scss';
import { SspProviderContext } from '../../../did-utility';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import { OverlayContentProps } from '../interfaces';
import i18next from 'i18next';

export default function Tire(props: OverlayContentProps): JSX.Element {
	const store = useContext(SspProviderContext);
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const tyreLabels = contentModel
		.ref('vehicle_data')
		.asModel.reflist('tyre_labels');
	const [loaded, setLoaded] = useState<Array<string>>([]);

	// limit loading time to 10s
	setTimeout(() => {
		setLoaded(tyreLabels.map(l => l.uid));
	}, 10000);

	useEffect(() => {
		if (loaded.length >= tyreLabels.length) {
			window.dispatchEvent(
				new CustomEvent('notify-scrollable-height-change')
			);
		}
	}, [loaded]);

	return (
		<div className='tire'>
			<div className='sticky-top'>
				{props.closeBtn()}
				<div className='side-padding-overlay'>
					<h2>{i18next.t('TireLabelsHeadline')}</h2>
					<p className='long'>{i18next.t('TireLabelsDescription')}</p>
					{i18next.exists('TireLabelInformationURL') && (
						<div className='general-info'>
							<h6 className='bold'>
								{i18next.t('TireLabelInformation') + ':'}
							</h6>
							<QRCodeSVG
								value={i18next.t('TireLabelInformationURL')}
								size={121}
								bgColor={'#ffffff'}
								fgColor={'#000000'}
								level={'M'}
								includeMargin={false}
							/>
						</div>
					)}
					<div className='info-box'>
						<svg className='icon-24'>
							<use xlinkHref='#system-info' />
						</svg>
						<p className='long bold'>
							{i18next.t('TireLabelOrderHintHeadline')}
						</p>
						<p className='long'>
							{i18next.t('TireLabelOrderHint')}
						</p>
					</div>
					<h4 className='border'>
						{i18next.t('TireLabelMainTires')}
					</h4>
				</div>
			</div>
			<div
				className={`tire-list side-padding-overlay${
					loaded.length < tyreLabels.length ? ' loading' : ''
				}`}
			>
				{loaded.length < tyreLabels.length && (
					<div className='loading-indicator'>
						<div />
					</div>
				)}
				{tyreLabels.map((label, index) => (
					<div
						className='tire-list-element'
						key={`tire-list-element-${index}`}
					>
						<img
							src={
								label.asModel.ref('tyrelabel_asset').asAsset.url
							}
							alt={label.asModel
								.ref('tyrelabel_asset')
								.asModel.str('productname')}
							onLoad={() => {
								setLoaded(prev => [...prev, label.uid]);
							}}
						/>
						<h4 className='bold'>
							{i18next.t('TireLabelProductDatasheet') + ':'}
						</h4>
						<QRCodeSVG
							className='qr'
							value={label.asModel.str('productSheetUrl')}
							size={121}
							bgColor={'#ffffff'}
							fgColor={'#000000'}
							level={'M'}
							includeMargin={false}
						/>
					</div>
				))}
			</div>
			<div className='padding-bottom' />
		</div>
	);
}
