import React, { useContext, useRef } from 'react';
import './styles.scss';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import {
	addOverlay,
	NavEnum,
	OverlayEnum,
	SspProviderContext,
} from '../../did-utility';
import PriceInfo from '../PriceInfo';
import Overview from '../ScrollableArea/Overview';
import Equipment from '../ScrollableArea/Equipment';
import TechData from '../ScrollableArea/TechData';
import HeaderContent from '../../did-utility/src/Components/HeaderContent';
import {
	SET_NAV_TAB_ACTION,
	SET_OVERLAYS_ACTION,
} from '../../did-utility/src/SspProvider/store';
import Footer from '../Footer';
import HeightLimitCarousel from '../../did-utility/src/Components/Carousel/HeightLimit';
import NotifyScrollable from '../../did-utility/src/Components/NotifyScrollable';
import Overlay from '../Overlay';
import i18next from 'i18next';
import { hasHeaderContent } from '../../did-utility/src/Components/HeaderContent/hasHeaderContent';

export default function MainPage(): JSX.Element {
	const store = useContext(SspProviderContext);
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const headerContent = hasHeaderContent(contentModel) ? (
		<HeaderContent />
	) : null;

	window.addEventListener('reset', () => {
		store.dispatch({
			type: SET_OVERLAYS_ACTION,
			value: [],
			noLog: true,
		});
		store.dispatch({
			type: SET_NAV_TAB_ACTION,
			value: NavEnum.Overview,
			noLog: true,
		});
	});

	const headerRef = useRef<HTMLDivElement>(null);

	const vehicleDataModel = contentModel.ref('vehicle_data').asModel;
	const techEntries = contentModel.ref('engine').asModel.reflist('entries');
	const tyreLabels = contentModel
		.ref('vehicle_data')
		.asModel.reflist('tyre_labels');
	const hasNEDCData = vehicleDataModel
		.keys()
		.some(
			key =>
				key.includes('nedc') &&
				!(
					(key.includes('efficiency') &&
						vehicleDataModel.str('nedc_energy_efficiency') ===
						'none') ||
					vehicleDataModel.str('nedc_energy_efficiency') === '-'
				)
		);
	const hasWLTPData = vehicleDataModel
		.keys()
		.some(key => key.includes('wltp'));
	let tyreString = '';

	tyreLabels.forEach((label, index) => {
		tyreString +=
			label.asModel.str('type') +
			' ' +
			label.asModel.str('tyreDesignation') +
			(index === tyreLabels.length - 1 ? '' : ', ');
	});


	const techDataEmpty = techEntries.length === 0 && tyreString.length === 0 && !hasNEDCData && !hasWLTPData

	let sibItems = [] as Array<SSPContentVO>;
	if (contentModel.has('color_in_image')) {
		sibItems = contentModel
			.ref('color_in_image')
			.asModel.reflist('packageItems')
			.filter(item => item.asModel.str('family') === 'SIB');
	}
	const languages = Object.keys(
		i18next.options.resources as Object
	);let padTypeValue;
	if (sibItems.length > 0 && languages.length > 1) {
		padTypeValue = sibItems[0].asModel.target.layertitle[i18next.language]
	} else if (sibItems.length > 0 && languages.length === 1) {
		padTypeValue = sibItems[0].asModel.str('layertitle')
	} else {padTypeValue = null}
	const colors = [
		{ name: 'ColorOut', key: 'color_out' },
		{ name: 'ColorSeat', key: 'color_seat' },
		{
			name: 'PadType',
			value: padTypeValue
		},
		{ name: 'ColorArm', key: 'color_arm' },
		{ name: 'ColorCarpet', key: 'color_carpet' },
		{ name: 'ColorSky', key: 'color_sky' },
	].filter(
		color =>
			(color.key &&
				contentModel.has(color.key) &&
				contentModel.str(color.key).trim() !== '') ||
			color.value
	);
	const serieEquip = contentModel.reflist('serie_equip');
	const specialEquip = contentModel.reflist('special_equip');

	const equipmentEmpty = colors.length === 0 && specialEquip.length === 0 && serieEquip.length ===0;

	return (
		<NotifyScrollable
			className='main-page'
			id={'main-page'}
			dontShow={store.state.overlays.length > 0}
			onScroll={() => {
				if (headerContent !== null) {
					const topDistance =
						headerRef.current?.getBoundingClientRect().top || 0;
					if (topDistance < 0) {
						headerRef.current?.classList.add('sticking');
					} else {
						headerRef.current?.classList.remove('sticking');
					}
				}
			}}
		>
			{headerContent !== null && (
				<div className='header-content'>{headerContent}</div>
			)}
			<header
				ref={headerRef}
				className={`${
					store.state.navTab === NavEnum.Overview ? 'dark' : ''
				}${headerContent === null ? ' sticking' : ''}`}
			>
				<div className='header'>
					<div className='header-title'>
						<div>
							{contentModel.bool('sold') && (
								<div className='sold'>
										<h4 className='bold'>{i18next.t('Sold')}</h4>
								</div>
							)}
							<p className='car-condition big'>
								{i18next.t(
									store.state.content?.contentType ===
										'Pricesheet'
										? 'ConditionNewCar'
										: 'ConditionUsedCar'
								)}
							</p>
							<h1>{contentModel.str('title')}</h1>
							{contentModel.str('subtitle') !== '-' && (
								<h1 className='light'>
									{contentModel.str('subtitle')}
								</h1>
							)}
						</div>
					</div>
					{headerContent === null && (
						<PriceInfo
							hidden={store.state.navTab !== NavEnum.Overview}
						/>
					)}
					<div className='header-nav-bar'>
						<div
							className={`header-nav-bar-element menu${
								store.state.navTab === NavEnum.Overview
									? ' active'
									: ''
							}`}
							onClick={() =>
								store.dispatch({
									type: SET_NAV_TAB_ACTION,
									value: NavEnum.Overview,
								})
							}
						>
							{i18next.t('TabOverview')}
						</div>
						{!equipmentEmpty && ( <div
							className={`header-nav-bar-element${
								store.state.navTab === NavEnum.Equipment
									? ' active'
									: ''
							}`}
							onClick={() =>
								store.dispatch({
									type: SET_NAV_TAB_ACTION,
									value: NavEnum.Equipment,
								})
							}
						>
							{i18next.t('TabEquipment')}
						</div>)}
						{equipmentEmpty && (<div
							className={`header-nav-bar-element${
								equipmentEmpty? ' empty' : ''
							}`}
						>
							{i18next.t('TabEquipment')}
						</div>)}
						{!techDataEmpty && (
							<div
								className={`header-nav-bar-element${
									store.state.navTab === NavEnum.TechData
										? ' active'
										: ''
								}`}
								onClick={() =>
									store.dispatch({
										type: SET_NAV_TAB_ACTION,
										value: NavEnum.TechData,
									})
								}
							>
								{i18next.t('TabTechnicalData')}
							</div>)}
						{techDataEmpty && (
							<div
								className={`header-nav-bar-element${
									techDataEmpty? ' empty' : ''
								}`}
							>
								{i18next.t('TabTechnicalData')}
							</div>)}
						{contentModel.has('salesgroupref') && (
							<div
								className='header-nav-bar-element menu-btn'
								onClick={() =>
									addOverlay(store, OverlayEnum.Menu)
								}
							>
								{i18next.t('TabMoreInformationModel')}
								<svg className='icon-24'>
									<use xlinkHref='#menu' />
								</svg>
							</div>
						)}
					</div>
				</div>
			</header>
			{headerContent !== null && (
				<PriceInfo hidden={store.state.navTab !== NavEnum.Overview} />
			)}
			<HeightLimitCarousel
				currentIndex={store.state.navTab}
				setCurrentIndex={(index: number) =>
					store.dispatch({
						type: SET_NAV_TAB_ACTION,
						value: index,
					})
				}
				margin={58} // $side-padding
				looping={true}
				keyName={'main-page-carousel'}
			>
				<Overview />
				<Equipment />
				<TechData />
			</HeightLimitCarousel>
			<Footer />
			<div
				className={`menu-flyout${
					store.state.overlays.includes(OverlayEnum.Menu)
						? ' open '
						: ''
				}`}
			>
				<Overlay overlay={OverlayEnum.Menu} />
			</div>
		</NotifyScrollable>
	);
}
