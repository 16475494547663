import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import { getCookie } from './getCookie';

const setup = getCookie('setup');
const showMinimalEquipmentPopups = setup?.market?.showMinimalEquipmentPopups;
export const showInfoIcon = (item: SSPContentVO) =>
    (showMinimalEquipmentPopups &&
        item.asModel
            .reflist('packageItems')
            .filter(item => item.asModel.bool('marketable', true)).length >
            0) ||
    item.asModel.reflist('assets').length > 0 ||
    item.asModel.str('description', '').length > 0 ||
    item.asModel.str('benefits', '').length > 0 ||
    item.asModel.str('teaserText', '').length > 0 ||
    item.asModel.str('remarks', '').length > 0 ||
    item.asModel.str('details', '').length > 0;
