import React, { useContext, useEffect, useState } from 'react';
import { getNumberString, SspProviderContext } from '../../../index';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import './styles.scss';
import { canvasFromPDF } from './canvasFromPDF';
import i18next from 'i18next';
import { checkHtml } from '../../Functions/checkHTML';
import { getCookie } from '../../Functions/getCookie';
import { dataLabel, DataLabelType, engineTypes } from './dataLabel';
import BackToSetup from '../BackToSetup';
import Loading from '../Loading';

var checkSWTimer: number | undefined;
export default function Label(props: { brand: string; apk: boolean }) {
    const setup = getCookie('setup');
    const orientationFromParams = new URLSearchParams(
        window.location.search
    ).get('orientation');
    if (orientationFromParams)
        localStorage.setItem('orientation', orientationFromParams);
    const orientation =
        orientationFromParams || localStorage.getItem('orientation') || '';
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content
        ? store.state.content.asModel
        : new SSPContentVO('').asModel;
    const asset = contentModel.ref('second_display');
    const type = asset.asAsset.fileProperties.fileType;

    const [swReady, setSWReady] = useState<boolean>(false);

	useEffect(() => {
		if (type === 'pdf') {
			canvasFromPDF(asset.asAsset.url);
		}
	});
    // repeatedly check if sw is registered
    function checkSW() {
        if (navigator.serviceWorker.controller) {
            setSWReady(true);
        } else {
            if (checkSWTimer) window.clearTimeout(checkSWTimer);
            checkSWTimer = window.setTimeout(
                () => checkSW(),
                1000 // 1s
            );
        }
    }

    // avoid loading asset in apk second screen without a service worker to provide the authorization!
    if (
        props.apk &&
        !swReady &&
        (type === 'pdf' || type === 'jpg' || type === 'png') &&
        'serviceWorker' in navigator
    ) {
        checkSW();

        return <Loading text={i18next.t('WaitingForPrimaryScreen')} />;
    }

    if (type === 'jpg' || type === 'png') {
        return (
            <div className={`label ${orientation}`}>
                <img
                    src={asset.asAsset.url}
                    alt={asset.asModel.str('description')}
                />
                <BackToSetup brand={props.brand} />
            </div>
        );
    }

    if (type === 'pdf') {
        return (
            <div className={`label ${orientation}`}>
                <div id='canvas' />
                <BackToSetup brand={props.brand} />
            </div>
        );
    }

    if (setup?.market?.nedc && contentModel.has('vehicle_data')) {
        const vehicleData = contentModel.ref('vehicle_data').asModel;
        const engine = contentModel.ref('engine').asModel;
        const engineType = engineTypes.includes(
            engine.str('engine_type', 'Default')
        )
            ? (engine.str('engine_type', 'Default') as keyof DataLabelType)
            : ('Default' as keyof DataLabelType);
        const data = dataLabel[engineType];

        const arrows = [
            { name: 'A+++', color: '#00A550' },
            { name: 'A++', color: '#00A550' },
            { name: 'A+', color: '#00A550' },
            { name: 'A', color: '#00A550' },
            { name: 'B', color: '#50B848' },
            { name: 'C', color: '#BED631' },
            { name: 'D', color: '#FFF103' },
            { name: 'E', color: '#FCB814' },
            { name: 'F', color: '#F26E22' },
            { name: 'G', color: '#ED1D23' },
        ];
        const index = arrows.findIndex(
            arrow => arrow.name === vehicleData.str('nedc_energy_efficiency')
        );

        return (
            <div className={`label ${orientation}`}>
                <div className='custom-label'>
                    <h2>{i18next.t('ENVKV_Title')}</h2>
                    <div className='top'>
                        <p>
                            <b>{i18next.t('ENVKV_Brand')}</b>{' '}
                            {engine.has('brand') &&
                            i18next.exists('ENVKV_Model_' + engine.str('brand'))
                                ? i18next.t(
                                      'ENVKV_Model_' + engine.str('brand')
                                  )
                                : props.brand}
                        </p>
                        <p>
                            <b>{i18next.t('ENVKV_Fuel')}</b>{' '}
                            {engine.has('fuel')
                                ? engine.str('fuel', '-')
                                : engine.str('fuel_type', '-')}
                        </p>
                        <p>
                            <b>{i18next.t('ENVKV_Model')}</b>{' '}
                            {vehicleData.str('model', '-')}
                        </p>
                        <p>
                            <b>{i18next.t('ENVKV_Fuel2')}</b>{' '}
                            {engine.str('fuel2', '-')}
                        </p>
                        <p className='extra-margin'>
                            <b>{i18next.t('ENVKV_Power')}</b>{' '}
                            {engine.str('engine_type') === 'Electric'
                                ? engine.str('fuel_power2')
                                : engine.str('fuel_power')}{' '}
                            {i18next.t('FuelPowerUnit')}
                        </p>
                        <p className='extra-margin'>
                            <b>{i18next.t('ENVKV_VehicleWeight')}</b>{' '}
                            {getNumberString(
                                vehicleData.float('vehicle_weight', NaN),
                                i18next.language,
                                'kg'
                            )}
                        </p>
                    </div>
                    <div className='middle'>
                        <div className='consumption-grid'>
                            <h2>{i18next.t('ENVKV_FuelConsumption')}</h2>
                            <div>
                                <h3>
                                    {i18next.t('ENVKV_FuelConsumptionCombined')}
                                </h3>
                                <h3 className='normal'>
                                    {engine.str('engine_type') === 'Petrol' ||
                                    'Diesel' ||
                                    'HybridPetrol' ||
                                    'Gas'
                                        ? getNumberString(
                                              vehicleData.float(
                                                  'nedc_consumption_combined' ||
                                                      '-',
                                                  NaN
                                              ),
                                              i18next.language,
                                              '',
                                              1
                                          )
                                        : '-'}
                                </h3>
                                <h3 className='normal'>
                                    {i18next.t(data.fuel.unit)}
                                </h3>
                                <h4>
                                    <b>
                                        {i18next.t(
                                            'ENVKV_FuelConsumptionUrban'
                                        )}
                                    </b>
                                </h4>
                                <h4>
                                    {getNumberString(
                                        vehicleData.float(
                                            'nedc_consumption_urban' || '-',
                                            NaN
                                        ),
                                        i18next.language,
                                        '',
                                        1
                                    )}
                                </h4>
                                <h4>{i18next.t(data.fuel.unit)}</h4>
                                <h4>
                                    <b>
                                        {i18next.t(
                                            'ENVKV_FuelConsumptionExtraUrban'
                                        )}
                                    </b>
                                </h4>
                                <h4>
                                    {getNumberString(
                                        vehicleData.float(
                                            'nedc_consumption_extra_urban' ||
                                                '-',
                                            NaN
                                        ),
                                        i18next.language,
                                        '',
                                        1
                                    )}
                                </h4>
                                <h4>{i18next.t(data.fuel.unit)}</h4>
                            </div>
                            <h2>{i18next.t('ENVKV_Co2Emission')}</h2>
                            <div>
                                <h3>
                                    {i18next.t('ENVKV_Co2EmissionCombined')}
                                </h3>
                                <h3 className='normal'>
                                    {getNumberString(
                                        vehicleData.float(
                                            'nedc_co2_combined',
                                            NaN
                                        ),
                                        i18next.language,
                                        '',
                                        0
                                    )}
                                </h3>
                                <h3 className='normal'>
                                    {i18next.t('ENVKV_Co2EmissionUnit')}
                                </h3>
                            </div>
                            <h2>{i18next.t('ENVKV_Electricity')}</h2>
                            <div>
                                <h3>
                                    {i18next.t('ENVKV_ElectricityCombined')}
                                </h3>
                                <h3 className='normal'>
                                    {engine.str('engine_type') === 'Electric'
                                        ? getNumberString(
                                              vehicleData.float(
                                                  'nedc_consumption_combined' ||
                                                      '-',
                                                  NaN
                                              ),
                                              i18next.language,
                                              '',
                                              1
                                          )
                                        : getNumberString(
                                              vehicleData.float(
                                                  'nedc_consumption_electric_combined' ||
                                                      '-',
                                                  NaN
                                              ),
                                              i18next.language,
                                              '',
                                              1
                                          )}
                                </h3>
                                <h3 className='normal'>
                                    {i18next.t('ENVKV_ElectricityUnit')}
                                </h3>
                            </div>
                        </div>
                        <p className='small'>
                            {i18next.t('ENVKV_DisclaimerConsumption')}
                        </p>
                        <p className='b'>
                            {i18next.t(
                                'ENVKV_DisclaimerConsumptionGuidlineTitle'
                            )}
                        </p>
                        <p className='small'>
                            {i18next.t(
                                'ENVKV_DisclaimerConsumptionGuidlineDescription'
                            )}
                        </p>
                    </div>
                    <div className='efficiency'>
                        <div className='headline'>
                            <h2>{i18next.t('ENVKV_Co2EfficencyTitle')}</h2>
                            <p>{i18next.t('ENVKV_Co2EfficencyDescription')}</p>
                        </div>
                        <div>
                            <div className='arrow-container'>
                                {arrows.map((arrow, index) => (
                                    <div
                                        className='arrow'
                                        key={`arrow-${index}`}
                                        style={{
                                            width:
                                                100 -
                                                (arrows.length - 1 - index) *
                                                    5 +
                                                '%',
                                            backgroundColor: arrow.color,
                                        }}
                                    >
                                        {arrow.name}
                                    </div>
                                ))}
                                {vehicleData.has('nedc_energy_efficiency') &&
                                    vehicleData.str(
                                        'nedc_energy_efficiency'
                                    ) !== 'none' &&
                                    vehicleData.str(
                                        'nedc_energy_efficiency'
                                    ) !== '-' && (
                                        <div
                                            className='black-arrow'
                                            style={{ top: `${index * 1.5}vh` }}
                                        >
                                            {vehicleData.str(
                                                'nedc_energy_efficiency'
                                            )}
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className='costs'>
                        <p className='small'>{i18next.t('ENVKV_AnnualTax')}</p>
                        <p className='small'>
                            <b>{i18next.t('ENVKV_CostCurrency')}</b>{' '}
                            {getNumberString(
                                vehicleData.float('annual_tax', NaN),
                                i18next.language,
                                '',
                                2
                            )}
                        </p>

                        <p className='small'>
                            {i18next.t('ENVKV_FuelCostTotal1')}
                        </p>
                        <p className='small' />

                        <p
                            className='small'
                            dangerouslySetInnerHTML={checkHtml(
                                i18next
                                    .t('ENVKV_FuelCostTotal2')
                                    .replace(
                                        '{{fuel}}',
                                        `<span class='underline'>${engine.str(
                                            data.cost.fuel || '-',
                                            '-'
                                        )}</span>`
                                    )
                                    .replace(
                                        '{{fuel_price}}',
                                        `<span class='underline'>${getNumberString(
                                            vehicleData.float(
                                                data.cost.fuelPrice || '-',
                                                NaN
                                            ),
                                            i18next.language
                                        )}</span>`
                                    )
                            )}
                        />
                        <p className='small'>
                            <b>{i18next.t('ENVKV_CostCurrency')}</b>{' '}
                            {getNumberString(
                                vehicleData.float(
                                    data.cost.fuelCostTotal || '-',
                                    NaN
                                ),
                                i18next.language,
                                '',
                                2
                            )}
                        </p>

                        <p
                            className='small'
                            dangerouslySetInnerHTML={checkHtml(
                                i18next
                                    .t('ENVKV_FuelCostTotal2Electricity')
                                    .replace(
                                        '{{fuel_price}}',
                                        `<span class='underline'>${getNumberString(
                                            vehicleData.float(
                                                data.cost.fuelPriceElectrical ||
                                                    '-',
                                                NaN
                                            ),
                                            i18next.language
                                        )}</span>`
                                    )
                            )}
                        />
                        <p className='small'>
                            <b>Euro</b>{' '}
                            {getNumberString(
                                vehicleData.float(
                                    data.cost.fuelCostElectricalTotal || '-',
                                    NaN
                                ),
                                i18next.language,
                                '',
                                2
                            )}
                        </p>

                        <p className='extra-margin small' />
                        <p className='extra-margin small'>
                            {i18next.t('ENVKV_GenerationDate')}{' '}
                            {new Date().toLocaleDateString(i18next.language, {
                                month: '2-digit',
                                day: '2-digit',
                                year: 'numeric',
                            })}
                        </p>
                    </div>
                </div>
                <BackToSetup brand={props.brand} />
            </div>
        );
    }

    return (
        <div className={`label ${orientation} label-default`}>
			<div className='wrapper'>
				<svg>
					<use xlinkHref='#brand-icon' />
				</svg>
				<h1>Digital Info Display</h1>
			</div>
            <BackToSetup brand={props.brand} />
        </div>
    );
}
