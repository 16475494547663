import React, { useContext } from 'react';
import './styles.scss';
import { goBack, OverlayEnum, SspProviderContext } from '../../did-utility';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import WheelsAndPaint from './OverlayContent/WheelsAndPaint';
import MediaCenter from './OverlayContent/MediaCenter';
import Common from './OverlayContent/Common';
import QRCode from './OverlayContent/QRCode';
import Leasing from './OverlayContent/Leasing';
import Tire from './OverlayContent/Tire';
import Consumption from './OverlayContent/Consumption';
import { OverlayProps } from './interfaces';
import NotifyScrollable from '../../did-utility/src/Components/NotifyScrollable';
import Menu from './OverlayContent/Menu';
import { QRCodeSVG } from 'qrcode.react';
import { SET_QR_ACTION } from '../../did-utility/src/SspProvider/store';
import Accessories from './OverlayContent/Accessories';
import i18next from 'i18next';

export default function Overlay(props: OverlayProps): JSX.Element {
	const store = useContext(SspProviderContext);

	const close = (justQR?: boolean) => {
		store.dispatch({
			type: SET_QR_ACTION,
			value: null,
		});
		if (!justQR) goBack(store);
	};

	const closeBtn = (text?: boolean, justQR?: boolean) => {
		return (
			<button
				className={
					text !== undefined ? 'close-btn fill' : 'close-btn-x'
				}
				onClick={() => close(justQR)}
			>
				{text ? (
					i18next.t('Close')
				) : (
					<svg className='icon-24'>
						<use xlinkHref='#quit' />
					</svg>
				)}
			</button>
		);
	};

	function overlayContent(overlay: OverlayEnum | SSPContentVO) {
		if ((overlay as SSPContentVO).contentType === 'EquipItem') {
			return (
				<Common overlay={overlay as SSPContentVO} closeBtn={closeBtn} />
			);
		}
		switch (props.overlay) {
			case OverlayEnum.Accessories:
				return <Accessories closeBtn={closeBtn} />;
			case OverlayEnum.MediaCenter:
				return <MediaCenter />;
			case OverlayEnum.Colors:
				return (
					<WheelsAndPaint
						closeBtn={closeBtn}
						overlay={OverlayEnum.Colors}
					/>
				);
			case OverlayEnum.Rims:
				return (
					<WheelsAndPaint
						closeBtn={closeBtn}
						overlay={OverlayEnum.Rims}
					/>
				);
			case OverlayEnum.QRCode:
				return <QRCode closeBtn={closeBtn} />;
			case OverlayEnum.Leasing:
				return <Leasing closeBtn={closeBtn} />;
			case OverlayEnum.Tire:
				return <Tire closeBtn={closeBtn} />;
			case OverlayEnum.Consumption:
				return <Consumption closeBtn={closeBtn} />;
			case OverlayEnum.Menu:
				return <Menu closeBtn={closeBtn} />;
			default:
				return <></>;
		}
	}

	if (props.overlay === null && store.state.qr === null) {
		return <></>;
	}

	const noPadding =
		props.overlay === OverlayEnum.Menu ||
		props.overlay === OverlayEnum.Colors ||
		props.overlay === OverlayEnum.Rims ||
		props.overlay === OverlayEnum.Accessories ||
		props.overlay === OverlayEnum.MediaCenter;
	const menu = props.overlay === OverlayEnum.Menu;
	const small = props.overlay === OverlayEnum.QRCode;
	const wheelsAndPaint =
		props.overlay === OverlayEnum.Colors ||
		props.overlay === OverlayEnum.Rims;
	const justQR = props.overlay === null;

	return (
		<div
			className={`overlay-container-outer${
				menu ? ' menu' : wheelsAndPaint ? ' wheels-paint' : ''
			}${justQR ? ' just-qr' : ''}`}
		>
			<div
				className={`overlay-container-inner${small ? ' small' : ''}${
					noPadding ? ' no-padding' : ''
				}`}
				onClick={e => {
					if (e.target === e.currentTarget) close();
				}}
			>
				<NotifyScrollable className={'overlay'}>
					{props.overlay !== null && overlayContent(props.overlay)}
					{store.state.qr && (
						<div
							className='qr-container'
							onClick={e => {
								if (e.target === e.currentTarget) close(true);
							}}
						>
							<div>
								<QRCodeSVG
									value={store.state.qr}
									size={210}
									bgColor={'#ffffff'}
									fgColor={'#000000'}
									level={'H'}
									includeMargin={false}
								/>
								<div>
									<p className='short'>
										{i18next.t('QRScanInfo')}
									</p>
									{closeBtn(true, true)}
								</div>
							</div>
						</div>
					)}
				</NotifyScrollable>
			</div>
			<div
				className={`close-btn-container ${
					props.overlay === OverlayEnum.Colors ||
					props.overlay === OverlayEnum.Rims ||
					props.overlay === OverlayEnum.Accessories ||
					props.overlay === OverlayEnum.MediaCenter
						? 'white'
						: ''
				}`}
				onClick={e => {
					if (e.target === e.currentTarget) close();
				}}
			>
				{closeBtn(true)}
			</div>
		</div>
	);
}
