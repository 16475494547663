import React, { useContext, useState } from 'react';
import './mediacenter.scss';
import { SspProviderContext } from '../../../did-utility';

import VideoPlayerVW from '../../VideoPlayerVW';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import i18next from 'i18next';

export default function MediaCenter(): JSX.Element {
	const store = useContext(SspProviderContext);

	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const mediaLib = contentModel
		.ref('salesgroupref')
		.asModel.ref('medialibrary');
	const videos = mediaLib.asModel
		.reflist('videos')
		.filter(item => item.asModel.bool('marketable', true));

	const [chosenVideo, setChosenVideo] = useState<SSPContentVO | null>(
		videos.length < 0 ? videos[0] : null
	);

	function getLength(video: SSPContentVO): string {
		const lengthInSeconds = parseInt(
			video.asAsset.fileProperties.fileMeta.target.length,
			10
		);
		var date = new Date(0);
		date.setSeconds(lengthInSeconds);
		return date.toISOString().substr(14, 5);
	}

	return (
		<div className='media-center'>
			<VideoPlayerVW
				video={chosenVideo || undefined}
				autoPlay={chosenVideo !== null}
			/>
			<h1 className='bold'>{i18next.t('LinkMediaCenter')}</h1>
			<div className='video-list'>
				{videos.map(video => (
					<div
						className={`video-list-element${
							video.uid === chosenVideo?.uid ? ' active' : ''
						}`}
						key={`video-${video.uid}`}
						onClick={() => {
							setChosenVideo(video);
						}}
					>
						<div className='video-box-right'>
							<h4 className='bold'>
								{video.asModel.str('name')}
							</h4>
							<p className='p1'>
								{video.asModel.str('description')}
							</p>
							<p className='p3'>
								<svg className='icon'>
									<use xlinkHref='#clock' />
								</svg>
								{i18next.t(
									'MediaCenterVideoLengthDescription'
								) +
									': ' +
									getLength(video) +
									' ' +
									i18next.t('MediaCenterVideoLengthUnit')}
							</p>
						</div>
						<div className='preview-img'>
							<img
								src={video.asAsset.posterUrl}
								alt={video.asModel.str('name')}
							/>
							<svg className='play-btn icon-48'>
								<use xlinkHref='#play-circle' />
							</svg>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
