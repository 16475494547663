import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';

interface dataItem {
    ModelYear: number;
    MotorkeyExtended: string;
    CityEmissions: number;
    HighwayEmissions: number;
    ObservedCombinedCO2Emissions: number;
    CityFuelEfficiency: number;
    HighwayFuelEfficiency: number;
    ObservedCombinedEfficiency: number;
}

export async function addMexData(priceSheet: SSPContentVO) {
    const engine = priceSheet.asModel.ref('engine');
    const motorKeyExtended = engine.asModel.str('motorkey_extended');
    const motorKey = engine.asModel.str('motorkey');
    const modelYear = priceSheet.asModel.integer('modelyear');

    try {
        const response = await fetch('./2023_es-MX_EmissionConsumption.json');
        //todo correct URL
        const data = await response.json();
        const matchingObject = data.find(
            (obj: dataItem) =>
                obj.MotorkeyExtended === motorKeyExtended ||
                (obj.MotorkeyExtended === motorKey &&
                    obj.ModelYear === modelYear)
        );
        const vehicleDataModel = priceSheet.asModel.ref('vehicle_data').asModel;
        [
            'CityEmissions',
            'HighwayEmissions',
            'ObservedCombinedCO2Emissions',
            'CityFuelEfficiency',
            'HighwayFuelEfficiency',
            'ObservedCombinedEfficiency',
        ].forEach(key => {
            vehicleDataModel.addValue(key, matchingObject[key]);
        });
    } catch (error) {
        console.error(error);
    }
}
