import React, { useContext, useEffect, useState } from 'react';
import './leasing.scss';
import { SspProviderContext } from '../../../did-utility';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import { OverlayContentProps } from '../interfaces';
import { fetchFinances } from '../../../did-utility/src/Functions/fetchFinances';
import i18next from 'i18next';
import { getCookie } from '../../../did-utility/src/Functions/getCookie';
import { marked } from 'marked';

let initialLeasing = true;
export default function Leasing(props: OverlayContentProps): JSX.Element {
	const store = useContext(SspProviderContext);
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const setup = getCookie('setup');
	const divId = 'vwfs';
	const [error, setError] = useState<string | false>(false);

	useEffect(() => {
		if (initialLeasing) {
			initialLeasing = false;

			fetchFinances(
				contentModel,
				'VW.NEW',
				divId,
				null,
				() => {
					initialLeasing = true;
					window.dispatchEvent(
						new CustomEvent('notify-scrollable-height-change')
					);
				},
				false,
				(error: any) => setError(error.error.description)
			);
		}
	}, []);

	type SSPContentVO = {
		Key: string;
		Value: string;
	};

	const autoFinancingCalculation = contentModel.ref(
		'auto_financing_calculation'
	);
	const financingData: SSPContentVO[] = contentModel
		.ref('auto_financing_calculation')
		.asModel.arr('financing_data');

	const financingProductTitle = autoFinancingCalculation.asModel.str('title');
	const financingProductDescription =
		autoFinancingCalculation.asModel.str('description');
	const financingDataInfo = contentModel
		.ref('auto_financing_calculation')
		.asModel.arr('financing_data_info');

	const [visibleIndex, setVisibleIndex] = useState(null);

	const clickHandler = (index: any) => {
		setVisibleIndex(visibleIndex === index ? null : index);
	};

	const fallback = (
		<>
			<h4>{financingProductTitle}</h4>
			<div
				className='financing-description'
				dangerouslySetInnerHTML={{
					__html: marked(financingProductDescription),
				}}
			></div>

			{financingData.map((dataItem, index) => (
				<div
					className={`${
						dataItem.Key.includes('divider')
							? 'divider'
							: 'financing-data-element'
					}`}
					key={`financing-data-element-${index}`}
				>
					<div className='financing-data-start'>
						<div
							className={`${
								dataItem.Key === 'FinancingProductTitle'
									? 'not-visible'
									: ''
							}`}
							dangerouslySetInnerHTML={{
								__html: dataItem.Key.split('{{')[0].trim(),
							}}
						/>
						{dataItem.Key.includes('{{') && (
							<div className='info-wrapper'>
								{financingDataInfo.find(
									(infoItem: { Key: string }) =>
										infoItem.Key &&
										dataItem.Key.includes(infoItem.Key)
								).Value && (
									<svg
										className='icon-24'
										onClick={() => clickHandler(index)}
									>
										<use xlinkHref='#system-info' />
									</svg>
								)}
								{visibleIndex === index &&
									financingDataInfo.find(
										(infoItem: { Key: string }) =>
											infoItem.Key &&
											dataItem.Key.includes(infoItem.Key)
									) && (
										<>
											<div className='visible'>
												<p
													dangerouslySetInnerHTML={{
														__html: financingDataInfo.find(
															(infoItem: {
																Key: string;
															}) =>
																infoItem.Key &&
																dataItem.Key.includes(
																	infoItem.Key
																)
														).Value,
													}}
												></p>
												<div className='padding' />
											</div>
											<div className='arrow' />
										</>
									)}
							</div>
						)}
					</div>

					<div
						className='financing-data-end'
						dangerouslySetInnerHTML={{
							__html: dataItem.Value,
						}}
					/>
				</div>
			))}
			<p
				className='disclaimer'
				dangerouslySetInnerHTML={{
					__html: marked(
						contentModel
							.ref('auto_financing_calculation')
							.asModel.str('disclaimer')
							.replaceAll('\n', '<br/>')
					),
				}}
			/>
			<p className='disclaimer'>{error}</p>
		</>
	);

	return (
		<div className='leasing'>
			{setup.market?.showWebCalc &&
				contentModel.bool('uselivecalculation') && (
					<>
						<div className='sticky-top'>
							{props.closeBtn()}
							<h2 className='side-padding-overlay'>
								{i18next.t(
									error
										? 'FinancingData'
										: 'CalculationOfferHeading'
								)}
							</h2>
						</div>
						<div
							id={divId}
							className='side-padding-overlay'
							onClick={() =>
								window.dispatchEvent(
									new CustomEvent(
										'notify-scrollable-height-change'
									)
								)
							}
						>
							{error !== false &&
								contentModel.has(
									'auto_financing_calculation'
								) &&
								fallback}
						</div>
						<div className='scrollable-bottom-padding' />
					</>
				)}
			{!contentModel.bool('uselivecalculation') &&
				contentModel.has('auto_financing_calculation') && (
					<>
						<div className='sticky-top'>
							{props.closeBtn()}
							<h2 className='side-padding-overlay'>
								{i18next.t('FinancingData')}
							</h2>
						</div>
						<div
							id='auto-financing'
							className='side-padding-overlay'
						>
							{fallback}
						</div>
						<div className='scrollable-bottom-padding' />
					</>
				)}
		</div>
	);
}
