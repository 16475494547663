import React, { useContext } from 'react';
import './menu.scss';
import {
	addOverlay,
	OverlayEnum,
	SspProviderContext,
} from '../../../did-utility';
import { OverlayContentProps } from '../interfaces';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import i18next from 'i18next';

export default function Menu(props: OverlayContentProps): JSX.Element {
	const store = useContext(SspProviderContext);
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const salesGroupModel = contentModel.ref('salesgroupref').asModel;

	const list = [
		{
			name: 'ExtIntColors',
			disabled: !(
				salesGroupModel
					.reflist('extColors')
					.filter(item => item.asModel.bool('marketable', true))
					.length > 0 ||
				salesGroupModel
					.reflist('intColors')
					.filter(item => item.asModel.bool('marketable', true))
					.length > 0
			),
			overlay: OverlayEnum.Colors,
		},
		{
			name: 'CompleteWheels',
			disabled: !(salesGroupModel.reflist('completeWheels').length > 0),
			overlay: OverlayEnum.Rims,
		},
		{
			name: 'LinkMediaCenter',
			disabled: !(
				salesGroupModel.has('medialibrary') &&
				salesGroupModel.ref('medialibrary').asModel.reflist('videos')
					.length > 0
			),
			overlay: OverlayEnum.MediaCenter,
		},
		{
			name: 'LinkAccessories',
			disabled: !salesGroupModel.has('accessories'),
			overlay: OverlayEnum.Accessories,
		},
	];

	return (
		<div className='menu'>
			{props.closeBtn()}
			<div>
				<h1>{i18next.t('TabMoreInformationModel')}</h1>
				<p>{i18next.t('TabMoreInformationModelDetail')}</p>
				<div className='menu-list'>
					{list.map((entry, index) => (
						<div
							key={`menu-list-${index}`}
							className={entry.disabled ? 'disabled' : ''}
							onClick={() => {
								if (!entry.disabled)
									addOverlay(store, entry.overlay);
							}}
						>
							<h1 className='light'>{i18next.t(entry.name)}</h1>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
