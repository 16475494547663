import React, { useContext, useEffect, useState } from 'react';
import './wheelsandpaint.scss';
import { OverlayEnum, SspProviderContext } from '../../../did-utility';
import { WheelsAndPaintProps } from '../interfaces';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import HeightLimitCarousel from '../../../did-utility/src/Components/Carousel/HeightLimit';
import { sortAlphabeticallyByKey } from '../../../did-utility/src/Functions/EquipListManagement';
import i18next from 'i18next';
import NotifyScrollable from '../../../did-utility/src/Components/NotifyScrollable';
import { logColorChange } from '../../../did-utility/src/ActivityLogger/util';

export default function Colors(props: WheelsAndPaintProps): JSX.Element {
	const store = useContext(SspProviderContext);
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const salesGroupModel = contentModel.ref('salesgroupref').asModel;

	let headline;
	let subheadline;
	let categories: Array<{
		name: string;
		list: Array<SSPContentVO>;
		header?: string;
		subHeader?: string;
		usedUids?: Array<string>;
	}> = [];
	const grid = 3;

	const [navElementIndex, setNavElementIndex] = useState<number>(0);
	useEffect(() => logColorChange(navElementIndex), [navElementIndex]);

	if (props.overlay === OverlayEnum.Colors) {
		headline = 'ExtIntColors';
		subheadline = 'ExtIntColorsSubheadlineVW';
		categories = [
			{
				name: 'ExtColors',
				list: sortAlphabeticallyByKey(
					sortAlphabeticallyByKey(
						salesGroupModel
							.reflist('extColors')
							.filter(item =>
								item.asModel.bool('marketable', true)
							),
						'layertitle'
					)
						.sort(a => {
							return a.uid ===
								contentModel.ref('color_out_image').uid
								? -1
								: 1;
						})
						.sort(a => {
							return a.asModel.has('assets') ? -1 : 1;
						}),
					'equipcategory'
				).sort(a => {
					return a.asModel.has('assets') ? -1 : 1;
				}),
				subHeader: 'equipcategory',
				usedUids: [contentModel.ref('color_out_image').uid],
			},
			{
				name: 'IntColors',
				list: sortAlphabeticallyByKey(
					sortAlphabeticallyByKey(
						salesGroupModel
							.reflist('intColors')
							.filter(item =>
								item.asModel.bool('marketable', true)
							),
						'layertitle'
					)
						.sort(a => {
							return a.uid ===
								contentModel.ref('color_in_image').uid
								? -1
								: 1;
						})
						.sort(a => {
							return a.asModel.has('assets') ? -1 : 1;
						}),
					'equipcategory'
				).sort(a => {
					return a.asModel.has('assets') ? -1 : 1;
				}),
				subHeader: 'equipcategory',
				usedUids: [contentModel.ref('color_in_image').uid],
			},
		];
	}

	const prString = String(contentModel.ref('prString').uid);

	if (props.overlay === OverlayEnum.Rims) {
		headline = 'CompleteWheels';
		categories = [
			{
				name: '',
				list: sortAlphabeticallyByKey(
					sortAlphabeticallyByKey(
						salesGroupModel
							.reflist('completeWheels')
							.filter(item =>
								item.asModel.bool('marketable', true)
							),
						'layertitle'
					).sort(a => {
						return a.asModel.has('assets') ? -1 : 1;
					}),

					'family'
				)
					.sort(a => {
						return a.asModel.has('assets') ? -1 : 1;
					})
					.sort(a => {
						return a.uid ===
							salesGroupModel
								.reflist('completeWheels')
								.filter(wheel =>
									prString.includes(
										wheel.asModel.str('number')
									)
								)
								.map(wheel => wheel.uid)[0] ||
							salesGroupModel
								.reflist('completeWheels')
								.filter(wheel =>
									prString.includes(
										wheel.asModel.str('number')
									)
								)
								.map(wheel => wheel.uid)[1]
							? -1
							: 1;
					}),
				header: 'family',
				usedUids: salesGroupModel
					.reflist('completeWheels')
					.filter(wheel =>
						prString.includes(wheel.asModel.str('number'))
					)
					.map(wheel => wheel.uid),
			},
		];
	}

	return (
		<NotifyScrollable>
			<div className='wheels-and-paint'>
				<div className='sticky-top'>
					{props.closeBtn()}
					<h1>{i18next.t(headline || '')}</h1>
					{subheadline && (
						<p className='p1 long'>{i18next.t(subheadline)}</p>
					)}
					{categories.length > 1 && (
						<div className='nav-bar'>
							{categories.map((category, index) => (
								<h5
									className={`normal nav-bar-element${
										navElementIndex === index
											? ' active'
											: ''
									}`}
									onClick={() => setNavElementIndex(index)}
									key={`nav-bar-element-colors-${index}`}
								>
									{i18next.t(category.name)}
								</h5>
							))}
						</div>
					)}
				</div>
				<div className={`carousel-container grid-${grid}`}>
					<HeightLimitCarousel
						currentIndex={navElementIndex}
						setCurrentIndex={setNavElementIndex}
						margin={99} // side-padding
						slidingDisabled={categories.length === 1}
						keyName={`wheels-and-paint-${props.overlay}-category`}
					>
						{categories.map(category => (
							<>
								{category.list.map((feature, index) => (
									<>
										{category.header &&
											(index === 0 ||
												feature.asModel.str(
													category.header
												) !==
													category.list[
														index - 1
													].asModel.str(
														category.header
													)) && (
												<h4 className='header bold'>
													{feature.asModel.str(
														category.header
													) === 'RAD' &&
														i18next.t(
															'ExtIntColorsSummerCompleteWheelsRAD'
														)}
													{feature.asModel.str(
														category.header
													) === 'ZBR' &&
														i18next.t(
															'ExtIntColorsWinterCompleteWheelsZBR'
														)}
												</h4>
											)}
										{category.subHeader &&
											(index === 0 ||
												feature.asModel.str(
													'equipcategory'
												) !==
													category.list[
														index - 1
													].asModel.str(
														'equipcategory'
													)) && (
												<h4 className='sub-header'>
													{feature.asModel.str(
														'equipcategory'
													)}
												</h4>
											)}
										<div
											className={`${
												!feature.asModel.has('assets')
													? 'no-img'
													: 'with-img'
											}`}
										>
											<div className='img'>
												{feature.asModel.has(
													'assets'
												) && (
													<img
														src={
															feature.asModel.reflist(
																'assets'
															)[0].asAsset.url
														}
														alt={feature.asModel.str(
															'layertitle'
														)}
													/>
												)}
												{category.usedUids &&
													category.usedUids.includes(
														feature.uid
													) && (
														<p className='p2 bold'>
															{i18next.t(
																'UsedFeature'
															)}
														</p>
													)}
											</div>
											<p className='p1 short'>
												{feature.asModel.str(
													'layertitle'
												)}
											</p>
										</div>
									</>
								))}
							</>
						))}
					</HeightLimitCarousel>
				</div>
			</div>
		</NotifyScrollable>
	);
}
