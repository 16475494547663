import { fetchWithTimeout, makeGUID, STORAGE_KEY } from './util';
import { getCookie } from '../Functions/getCookie';
import LogData from './interfaces';

const setup = getCookie('setup');
const TRACKING_URL = setup?.url || null;
const DEVICE = setup?.device || '';
const LANGUAGE = setup?.language || '';
let apiToken: string | undefined;
const params = new URLSearchParams(window.location.search);
const apk = params.get('apk') === 'true';
const webDid = !apk;

function sendData(data: any) {
    try {
        if (TRACKING_URL && apiToken) {
            fetchWithTimeout(
                `${TRACKING_URL}/module/stats/bpca/api/v2/events?apiToken=${apiToken}`,
                data
            );
        } else if (TRACKING_URL) {
            const auth = window.btoa(`ssp:${setup?.password || ''}`);
            fetch(`${TRACKING_URL}/module/content/bpca/depot/os/statistics`, {
                method: 'GET',
                headers: {
                    Authorization: 'Basic ' + auth,
                    'Service-Worker': 'false',
                },
            }).then(async res => {
                if (res.status === 200) {
                    apiToken = (await res.text()).replace(/(\r\n|\n|\r)/gm, '');
                    sendData(data);
                } else {
                    console.error('Tracking token not found!');
                }
            });
        }
    } catch (error) {
        console.error(error);
    }
}

export default function dispatchLogEvent(data: LogData) {
    if (navigator.onLine && !window.location.href.includes('localhost')) {
        const timezoneOffset = new Date().getTimezoneOffset() * 60000;
        const localISOTime = new Date(
            (data.Timestamp || Date.now()) - timezoneOffset
        ).toISOString();
        let completeData: any = {
			AdditionalData: {
				PricesheetTitle: sessionStorage.getItem('pricesheetTitle') || '',
				Carline: sessionStorage.getItem('carline') || '',
				CarlineName: sessionStorage.getItem('carlineName') || '',
				Salesgroup: sessionStorage.getItem('salesgroup') || '',
				SalesgroupName: sessionStorage.getItem('salesgroupName') || '',
				ModelKey: sessionStorage.getItem('modelKey') || '',
				EngineName: sessionStorage.getItem('engineName') || '',
				ModelYear: sessionStorage.getItem('modelYear') || '',
				Vin: sessionStorage.getItem('vin') || '',
				CommissionNumber: sessionStorage.getItem('commissionNumber') || '',
				WebDid: webDid,
			},DeviceId: DEVICE,
            DeviceName: DEVICE,
            SuiteName: sessionStorage.getItem('suite') || '',
            DeviceLanguage: document.documentElement.lang || LANGUAGE,
            SessionId: sessionStorage.getItem(STORAGE_KEY) || makeGUID(),
            ...data,
            Timestamp: localISOTime,
        };

        sendData(completeData);
    }
}
