import './styles.scss';

export default function ErrorPage(props: {
    msg: string;
    status?: number;
    setup?: any;
    link: string;
}) {
    if (props.setup && props.setup.password) {
        props.setup.password = '*'.repeat(props.setup.password.length);
    }
    return (
        <div className='error-page'>
            <h2>Error{props.status && ' ' + props.status}</h2>
            <h6>{props.msg}</h6>
            <br />
            {props.setup &&
                Object.keys(props.setup).map(key => (
                    <p key={`error-setup-${key}`}>
                        {key}:{' '}
                        {props.setup[key]
                            ? JSON.stringify(props.setup[key])
                            : 'undefined'}
                    </p>
                ))}
            <a href={props.link}>
                {props.link.includes('setup')
                    ? 'Click here to reconfigure'
                    : props.link}
            </a>
        </div>
    );
}
