export function registerSW(password: string) {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker
			.register('/service-worker.js', { scope: '/' })
			.then(registration => {
				console.log('service worker registered');
				const data = {
					type: 'CACHE_URLS',
					payload: new Set(
						[
							'/',
							...performance
								.getEntriesByType('resource')
								.map(r => r.name)
								.filter(name =>
									name.includes(window.location.origin)
								),
							'/src/assets/fonts/VWText-Regular.otf',
							'/src/assets/fonts/VWText-Bold.otf',
							'/src/assets/fonts/VWHead.ttf',
							'/src/assets/fonts/VWHead-Light.ttf',
							'/src/assets/fonts/VWHead-Bold.ttf',
						].map(url => url.replace(window.location.origin, ''))
					),
				};
				if (registration.installing) {
					registration.installing.postMessage(data);
					registration.installing.postMessage({
						type: 'AUTH',
						payload: window.btoa(`ssp:${password}`),
					});
				}
			})
			.catch(err =>
				console.log('service worker registration failed:', err)
			);
	}
}
