import SSPContentFieldAccessor from 'sspcontentext/dist/model/accessor/SSPContentFieldAccessor';
import React from 'react';
import { getCookie } from './getCookie';

const domain = getCookie('setup')?.market?.calcDomain;

export function fetchFinances(
    contentModel: SSPContentFieldAccessor,
    fallbackDomain: string,
    id: string | null,
    setCalc: React.Dispatch<any> | null,
    callback: Function | null,
    extended?: boolean,
    errorCallback?: Function
) {
    let calculator: any = null;
    try {
        //@ts-ignore
        calculator = new Calculator();
    } catch {
        console.warn('no calculator');
    }

    let key = contentModel.ref('engine').asModel.str('motorkey');

    if (extended) {
        const parts = contentModel
            .ref('engine')
            .asModel.str('motorkey_extended')
            .split('-');
        key = parts[0];
        for (let i = 1; i < parts.length; i++) {
            key += '/' + parts[i].slice(parts[i].length - 3, parts[i].length);
        }
    }

    const request =
        contentModel.has('auto_financing_calculation') &&
        contentModel
            .ref('auto_financing_calculation')
            .asModel.has('original_request')
            ? JSON.parse(
                  contentModel
                      .ref('auto_financing_calculation')
                      .asModel.str('original_request')
              )
            : {
                  Request: {
                      '@Domain': domain || fallbackDomain,
                      Product: {},
                      Vehicle: {
                          ID: contentModel.str('carline'),
                          Key: key,
                          Year: contentModel.float('modelyear'),
                          PriceTotal:
                              contentModel.float('price', 0) ||
                              contentModel.float('price_api', 0) ||
                              undefined,
                      },
                  },
              };

    if (calculator) {
        if (id !== null) {
            calculator
                .getInterface(request, {
                    // UI Options
                    ErrorCallback: errorCallback,
                    ResultContainer: document.getElementById(id),
                })
                .then(callback);
        } else if (setCalc !== null) {
            calculator
                .getDefaultsAsync(request, {
                    // Core Options
                })
                .then((defaultsResponses: any) => {
                    if (defaultsResponses) {
                        const first = defaultsResponses.getFirst();
                        first.expires = Date.now() + 24 * 60 * 60 * 1000;
                        setCalc(
                            first.error && first.error.fatal ? null : first
                        );
                    } else {
                        setCalc(null);
                    }
                })
                .catch(() => setCalc(null));
        }
    }
}
