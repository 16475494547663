import React, { useEffect, useState } from 'react';
import './styles.scss';
import i18next from 'i18next';
import { KeyboardWrapper } from '../../../index';
import { getCookie } from '../../Functions/getCookie';

export default function BackToSetup(props: {
    brand: string;
    langBtnId?: string;
}) {
    const [clicks, setClicks] = useState<number>(0);
    const [lastClick, setLastClick] = useState<number | null>(null);
    const [iFrame, setIFrame] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [freeSpace, setfreeSpace] = useState<number>(0);
    const [pw, setPW] = useState<string>('');

    const newUrl = new URL(
        window.location.href
            .replace(props.brand, 'setup')
            .replace('redesign', 'dev')
            .replace('localhost:3000', 'localhost:3001')
    );
    const apk = newUrl.searchParams.get('apk') === 'true';
    newUrl.searchParams.set('redirect', 'false');

    function backFunction() {
        if (lastClick === null || Date.now() - lastClick > 1000) {
            setLastClick(Date.now());
            setClicks(1);
        } else {
            if (clicks >= 12) {
                setIFrame(true);
            }
            setLastClick(Date.now());
            setClicks(prev => prev + 1);
        }
    }

    window.addEventListener('message', e => {
        if (e.data === 'close') {
            setIFrame(false);
            setClicks(0);
        }
        if (e.data === 'redirect') {
            newUrl.searchParams.set('change-device', 'true');
            window.location.replace(newUrl);
        }
        if (e.data === 'locked') {
            newUrl.searchParams.set('locked', 'false');
            window.location.replace(newUrl);
        }
        if (e.data === 'error') {
            setIFrame(false);
            setError(true);
            setTimeout(() => setError(false), 3000);
        }
    });

    useEffect(() => {
        if (props.langBtnId) {
            const langBtn = document.getElementById(props.langBtnId);
            if (langBtn && langBtn.getBoundingClientRect().width) {
                setfreeSpace(
                    ((langBtn.getBoundingClientRect().x +
                        langBtn.getBoundingClientRect().width) /
                        window.innerWidth) *
                        100
                );
            }
        }
    }, []);

    return (
        <div
            className='back-to-setup'
            onClick={backFunction}
            style={{
                width: 100 - freeSpace + 'vw',
            }}
        >
            {!error &&
                new URLSearchParams(window.location.search).get('dev') ===
                    'true' && <h1>{clicks}</h1>}
            {error && (
                <h1 style={{ transform: `translate(${-freeSpace / 2}vw)` }}>
                    Error
                </h1>
            )}
            {navigator.onLine && iFrame && (
                <iframe src={newUrl + '&in-frame=true'} />
            )}
            {!navigator.onLine && iFrame && (
                <div className='iframe-replacement-offline'>
                    <button onClick={() => setIFrame(false)}>
                        {i18next.t('Close')}
                    </button>
                    <h1>
                        {i18next.t('iFrameOfflineHeadline') ===
                        'iFrameOfflineHeadline'
                            ? 'You are offline.'
                            : i18next.t('iFrameOfflineHeadline')}
                    </h1>
                    <p>
                        {i18next.t('iFrameOfflineText') === 'iFrameOfflineText'
                            ? 'Please check your WIFI settings.'
                            : i18next.t('iFrameOfflineText')}
                    </p>
                    {apk && (
                        <div className='pw'>
                            <input
                                inputMode='none'
                                value={pw}
                                type={'password'}
                                onChange={e => setPW(e.target.value)}
                            />
                            <KeyboardWrapper
                                onChange={i => setPW(i)}
                                onEnter={() => {
                                    const cookie = getCookie('setup');
                                    console.log(cookie, pw);
                                    if (
                                        pw &&
                                        (pw === cookie.userPassword ||
                                            pw ===
                                                `${
                                                    cookie.kvps ||
                                                    cookie.brand ||
                                                    'setup'
                                                }@did`)
                                    ) {
                                        newUrl.searchParams.set(
                                            'locked',
                                            'false'
                                        );
                                        window.location.replace(newUrl);
                                    }
                                }}
                                enterString={i18next.t('Enter')}
                                langCode={'en-US'}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
