import React, { useContext } from 'react';
import './styles.scss';
import { addOverlay, OverlayEnum, SspProviderContext } from '../../did-utility';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import {
	UpperTableElement,
	UpperTableElementNedcWltp,
	UpperTableElementNumber,
} from './upperTableElement';
import i18next from 'i18next';
import { checkHtml } from '../../did-utility/src/Functions/checkHTML';
import { TeaserImage } from '../../did-utility/src/Components/TeaserImage';

export default function Overview(): JSX.Element {
	const store = useContext(SspProviderContext);
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const vehicleDataModel = contentModel.ref('vehicle_data').asModel;
	const engine = contentModel.ref('engine').asModel;
	const highlightEquip = contentModel.reflist('highlight_equip');
	const usedCar = store.state.content?.contentType === 'UsedPricesheet';

	const moreData = (
		<h6 className='more-data p2'>{i18next.t('MoreInformation')}</h6>
	);

	const maxHeightTeaserBox = (window.innerWidth - 2 * 58) * 0.565 * (9 / 16);

	return (
		<>
			<div className='upper-table overview'>
				{engine.str('engine_type') === 'HybridPetrol' ? (
					<div className='power'>
						{!(
							engine.str('max_output_kw') &&
							engine.str('max_output_ps')
						) ? (
							<UpperTableElement
								headline={'OverviewBoxPower'}
								value={engine.str('fuel_power', '-')}
								unit={i18next.t('FuelPowerUnit')}
								valuep2={engine.str('fuel_power_PS', '-')}
								unitp2={i18next.t('FuelPowerPSUnit')}
							/>
						) : (
							<UpperTableElement
								headline={'OverviewBoxSystemPower'}
								value={engine.str('max_output_kw', '-')}
								unit={i18next.t('FuelPowerUnit')}
								valuep2={engine.str('max_output_ps', '-')}
								unitp2={i18next.t('FuelPowerPSUnit')}
							/>
						)}
					</div>
				) : engine.str('engine_type') === 'Electric' ? (
					<div className='electric power'>
						{!(
							engine.str('max_output_kw') &&
							engine.str('max_output_ps')
						) ? (
							<UpperTableElement
								headline={'OverviewBoxElectricPower'}
								value={engine.str('fuel_power2', '-')}
								unit={i18next.t('FuelPowerUnit')}
								valuep2={engine.str('fuel_power2_PS', '-')}
								unitp2={i18next.t('FuelPowerPSUnit')}
							/>
						) : (
							<UpperTableElement
								headline={'OverviewBoxElectricPower'}
								value={engine.str('max_output_kw', '-')}
								unit={i18next.t('FuelPowerUnit')}
								valuep2={engine.str('max_output_ps', '-')}
								unitp2={i18next.t('FuelPowerPSUnit')}
							/>
						)}
					</div>
				) : (
					<div className='petrol power'>
						{!(
							engine.str('max_output_kw') &&
							engine.str('max_output_ps')
						) ? (
							<UpperTableElement
								headline={'OverviewBoxPower'}
								value={engine.str('fuel_power', '-')}
								unit={i18next.t('FuelPowerUnit')}
								valuep2={engine.str('fuel_power_PS', '-')}
								unitp2={i18next.t('FuelPowerPSUnit')}
							/>
						) : (
							<UpperTableElement
								headline={'OverviewBoxPower'}
								value={engine.str('max_output_kw', '-')}
								unit={i18next.t('FuelPowerUnit')}
								valuep2={engine.str('max_output_ps', '-')}
								unitp2={i18next.t('FuelPowerPSUnit')}
							/>
						)}
					</div>
				)}
				{engine.str('engine_type') === 'Electric' &&
				engine.has('battery_charging_dc_100') ? (
					<UpperTableElement
						headline={'OverviewBoxChargingTime'}
						value={engine.str('battery_charging_dc_100', '-')}
						unit={i18next.t('ChargingTimeUnit')}
					/>
				) : engine.str('engine_type') === 'Electric' &&
				  engine.has('battery_charging_ac_100') ? (
					<UpperTableElement
						headline={'OverviewBoxChargingTime'}
						value={engine.str('battery_charging_ac_100', '-')}
						unit={i18next.t('ChargingTimeUnit')}
					/>
				) : engine.has('acceleration') ? (
					<UpperTableElementNumber
						headline={'OverviewBoxAcceleration'}
						value={engine.float('acceleration', NaN)}
						unit={'AccelerationUnit'}
					/>
				) : engine.has('top_speed') ? (
					<UpperTableElementNumber
						headline={'OverviewBoxTopSpeed'}
						value={engine.float('top_speed', NaN)}
						unit={'SpeedUnit'}
					/>
				) : engine.has('motor_drive') ? (
					<UpperTableElement
						headline={'OverviewBoxMotorDrive'}
						value={engine.str('motor_drive', '-')}
					/>
				) : engine.has('motor_gear') ? (
					<UpperTableElement
						headline={'OverviewBoxMotorGear'}
						value={engine.str('motor_gear', '-')}
					/>
				) : engine.str('engine_type') === 'Electric' ||
				  engine.str('engine_type') === 'HybridPetrol' ? (
					<UpperTableElement
						headline={'OverviewBoxFuelType'}
						value={engine.str('fuel2', '-')}
					/>
				) : (
					<UpperTableElementNumber
						headline={'OverviewBoxAcceleration'}
						value={engine.float('acceleration', NaN)}
						unit={'AccelerationUnit'}
					/>
				)}
				{engine.str('engine_type') === 'Electric' ||
				engine.str('engine_type') === 'HybridPetrol' ? (
					<UpperTableElementNedcWltp
						headline={'OverviewBoxRangeCombined'}
						value={'xxx_range_combined'}
						unit={i18next.t('LengthUnit')}
						model={vehicleDataModel}
					/>
				) : (
					<UpperTableElement
						headline={'OverviewBoxFuelType'}
						value={i18next.t(engine.str('engine_type'))}
					/>
				)}
				<div onClick={() => addOverlay(store, OverlayEnum.Consumption)}>
					<UpperTableElementNedcWltp
						headline={'OverviewBoxConsumption'}
						value={'xxx_consumption_combined'}
						unit={`${
							engine.str('engine_type') === 'Electric'
								? 'ElectricConsumptionUnit'
								: engine.str('engine_type') === 'Gas'
									? 'GasConsumptionUnit'
									: 'FuelConsumptionUnit'
						}`}
						model={vehicleDataModel}
						engine={engine}
						add={moreData}
					/>
				</div>

				{usedCar && (
					<>
						<UpperTableElement
							headline={'InitialRegistration'}
							value={new Date(
								contentModel.obj('initialreg').Timestamp
							).toLocaleDateString(i18next.language, {
								year: 'numeric',
								month: '2-digit',
								day: '2-digit',
							})}
						/>
						<UpperTableElementNumber
							headline={'Mileage'}
							value={contentModel.float('mileage', NaN)}
							unit={i18next.t('LengthUnit')}
						/>
						<UpperTableElement
							headline={'NextGeneralInspection'}
							value={contentModel.str('generalinspection', '-')}
						/>
						<UpperTableElement
							headline={'WarrantyType'}
							value={
								contentModel.bool('warranty')
									? i18next.t('WarrantyTrue')
									: i18next.t('WarrantyFalse')
							}
						/>
					</>
				)}
			</div>

			{highlightEquip.map((equipItem, index) => (
				<div
					className='teaser-box'
					key={`teaser-box-${index}`}
					onClick={() => addOverlay(store, equipItem)}
				>
					{equipItem.asModel.has('assets') && (
						<>
							<TeaserImage
								equipItem={equipItem}
								iconVideo={'#play-circle'}
							/>
							<div className='teaser-box-right'>
								<p className='small category'>
									{(equipItem.asModel.target.equipcategory &&
										equipItem.asModel.target.equipcategory[
											i18next.language
										]) ||
										equipItem.asModel.str('equipcategory')}
								</p>
								<div className='truncate'>
									<h3>
										{(equipItem.asModel.target.layertitle &&
											equipItem.asModel.target.layertitle[
												i18next.language
											]) ||
											equipItem.asModel.str('layertitle')}
									</h3>
									<p
										className='long'
										dangerouslySetInnerHTML={checkHtml(
											equipItem.asModel.str('teasertext'),
											i18next.t('ClickMe')
										)}
									/>
								</div>
								<button className='small'>
									{i18next.t('LearnMore')}
								</button>
							</div>
						</>
					)}
				</div>
			))}
		</>
	);
}
