import SSPContentExt from 'sspcontentext';
import { SspProviderState } from './interfaces';
import { NavEnum, OverlayEnum } from '../enums';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import { logStateChange } from '../ActivityLogger/util';

export const SET_CONTENT_ACTION = 'SET_CONTENT';
export const SET_SSP_ACTION = 'SET_SSP';
export const SET_OVERLAYS_ACTION = 'SET_OVERLAYS';
export const SET_QR_ACTION = 'SET_QR';
export const SET_NAV_TAB_ACTION = 'SET_NAV_TAB';

export type ACTION =
    | { type: typeof SET_CONTENT_ACTION; value: SSPContentVO }
    | { type: typeof SET_SSP_ACTION; value: SSPContentExt }
    | { type: typeof SET_QR_ACTION; value: string | null }
    | { type: typeof SET_NAV_TAB_ACTION; value: NavEnum; noLog?: true }
    | {
          type: typeof SET_OVERLAYS_ACTION;
          value: Array<OverlayEnum | SSPContentVO>;
          noLog?: true;
      };

export const reducer = (
    state: SspProviderState,
    action: ACTION
): SspProviderState => {
    switch (action.type) {
        case SET_CONTENT_ACTION:
            return {
                ...state,
                content: action.value,
            };
        case SET_SSP_ACTION:
            return {
                ...state,
                ssp: action.value,
            };
        case SET_NAV_TAB_ACTION:
            if (!action.noLog) {
                window.dispatchEvent(new Event('restart-timer'));
            }
            logStateChange(state, action);
            return {
                ...state,
                navTab: action.value,
            };
        case SET_QR_ACTION:
            return {
                ...state,
                qr: action.value,
            };
        case SET_OVERLAYS_ACTION:
            if (!action.noLog) {
                window.dispatchEvent(new Event('restart-timer'));
            }
            logStateChange(state, action);
            return {
                ...state,
                overlays: action.value,
            };
        default:
            return state;
    }
};

export const INITIAL_STATE: SspProviderState = {
    content: null,
    ssp: null,
    overlays: [],
    qr: null,
    navTab: NavEnum.Overview,
};
