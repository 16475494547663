import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';

// gets the name of the equipcategory of an equipitem in the current language
// can split of unneeded "accessories - " etc
function categoryName(item: SSPContentVO, split: boolean): string {
    if (item.asModel.str('equipcategory')) {
        if (split) {
            return (
                item.asModel.str('equipcategory').split(' - ')[1] ||
                item.asModel.str('equipcategory').split(' – ')[1]
            );
        } else {
            return item.asModel.str('equipcategory');
        }
    }
    return '';
}

// returns list of categories in list of equip items without any double entries
export function filterForCategories(
    list: Array<SSPContentVO>,
    split: boolean
): Array<string> {
    const categoryList = list.map(item => categoryName(item, split));
    const filteredCategoryList = categoryList.filter(function (item, pos) {
        return categoryList.indexOf(item) === pos && item !== '';
    });
    return filteredCategoryList;
}

export function sortAlphabeticallyByKey(
    list: Array<SSPContentVO>,
    key: string
): Array<SSPContentVO> {
    return list.sort(function (a, b) {
        const aCategory = a.asModel.str(key).toLowerCase();
        const bCategory = b.asModel.str(key).toLowerCase();
        if (aCategory < bCategory) {
            return -1;
        }
        if (aCategory > bCategory) {
            return 1;
        }
        return 0;
    });
}

// sort a list by category (true) or by subcategory (false)
export function sortList(list: Array<SSPContentVO>, category: boolean) {
    return list.sort((item1: SSPContentVO, item2: SSPContentVO) => {
        if (
            category
                ? item1.asModel.str('equipcategoryid') === '.'
                : item1.asModel.str('subcategory') === undefined
        ) {
            return -1;
        } else {
            const index1 = list.findIndex((i: SSPContentVO) =>
                category
                    ? i.asModel.str('equipcategoryid') ===
                      item1.asModel.str('equipcategoryid')
                    : i.asModel.str('subcategory') ===
                      item1.asModel.str('subcategory')
            );
            const index2 = list.findIndex((i: SSPContentVO) =>
                category
                    ? i.asModel.str('equipcategoryid') ===
                      item2.asModel.str('equipcategoryid')
                    : i.asModel.str('subcategory') ===
                      item2.asModel.str('subcategory')
            );
            return index1 - index2;
        }
    });
}

// function to get sorted list of all equip items in a category
export function prepareList(
    list: Array<SSPContentVO>,
    category: string,
    split: boolean
): Array<SSPContentVO> {
    const categoryList = list.filter(item => {
        return categoryName(item, split) === category;
    });

    return sortList(categoryList, false);
}

// function to check if a string contains a substring matching the input string in at least 70% of characters
// returns list of indizes where string matches input string if position === true
function similarityCheck(
    string: string,
    inputString: string,
    position: boolean
) {
    const found: Array<number> = [];
    for (let i = 0; i < string.length; i++) {
        let counter = 0;
        const substring = string
            .substring(i, i + inputString.length)
            .toLowerCase();
        for (let j = 0; j < substring.length; j++) {
            if (substring[j] === inputString[j]) {
                counter++;
            }
        }

        if (counter / inputString.length >= 0.7) {
            if (position) {
                found.push(i);
            } else {
                return true;
            }
        }
    }

    return position ? found : false;
}

// searches for all equipment items containing the input string in title, description or teaser text
// or a very similar string (see similarityCheck) should there be no exact match due to a spelling error
export function searchResults(inputString: string, list: Array<SSPContentVO>) {
    const simpleSearch = list.filter(item => {
        return (
            item.asModel
                .str('layertitle')
                .toLowerCase()
                .includes(inputString.toLowerCase()) ||
            item.asModel
                .str('description')
                .toLowerCase()
                .includes(inputString.toLowerCase()) ||
            item.asModel
                .str('teasertext')
                .toLowerCase()
                .includes(inputString.toLowerCase())
        );
    });

    if (simpleSearch.length > 0) {
        return sortList(simpleSearch, true);
    } else {
        const thoroughSearch = list.filter(
            item =>
                similarityCheck(
                    item.asModel.str('layertitle'),
                    inputString,
                    false
                ) ||
                similarityCheck(
                    item.asModel.str('description'),
                    inputString,
                    false
                ) ||
                similarityCheck(
                    item.asModel.str('teasertext'),
                    inputString,
                    false
                )
        );

        return sortList(thoroughSearch, true);
    }
}

// return only the unique equipitems from a list of trimlevels
export function equipmentFromTrimlevels(
    trimlevels: Array<SSPContentVO>
): Array<SSPContentVO> {
    const numbers: Array<string> = [];
    const allUniqueEquipItems = [];
    for (const trimlevel of trimlevels) {
        for (const item of trimlevel.asModel.reflist('special_equip')) {
            if (!numbers.includes(item.asModel.str('number'))) {
                allUniqueEquipItems.push(item);
                numbers.push(item.asModel.str('number'));
            }
        }
    }
    return allUniqueEquipItems;
}
