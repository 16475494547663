export const ICON_HTML = `<div style="display: none; visibility: hidden; width: 0; height: 0;">
   <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   
    <!---------------------------------------------- NEW volkswagen icons ------------------------------------------------------>
   
    <!---------------------------------------------- 48 x 48 ------------------------------------------------------>
     <symbol id="info-icon-filled" viewBox="0 0 48 48">
          <path d="M22.013.013c-.489-.017-.98-.017-1.469,0a21.278,21.278,0,0,0-20.531,22q-.026.735,0,1.469a21.278,21.278,0,0,0,22,20.531q.735.025,1.47,0a21.278,21.278,0,0,0,20.53-22q.025-.735,0-1.469a21.278,21.278,0,0,0-22-20.531M21.905,11.14a1.554,1.554,0,0,1,.193,0,1.492,1.492,0,0,1,1.355,1.618,1.37,1.37,0,0,1,0,.157,1.55,1.55,0,1,1-3.1-.157,1.555,1.555,0,0,1-.005-.193,1.494,1.494,0,0,1,1.553-1.429m6.772,20.986H14.743v-2.66h6.068V20.828H16.733v-2.66h6.738l.006,11.3h5.2Z" transform="translate(2 2)" fill="#00b0f0"/>
     </symbol>
     <symbol id="system-info" viewBox="0 0 48 48">
      <g transform="translate(-1151 -581)">
    	<path d="M24,1C10.673,1,1,10.673,1,24s9.673,23,23,23,23-9.673,23-23S37.327,1,24,1m0,43.838C11.926,44.838,3.162,36.074,3.162,24S11.926,3.161,24,3.161,44.839,11.926,44.839,24,36.074,44.838,24,44.838m0-31.353a1.557,1.557,0,0,1,1.623,1.492,1.445,1.445,0,0,1,0,.208,1.62,1.62,0,1,1-3.237.139c0-.046,0-.093,0-.139a1.559,1.559,0,0,1,1.41-1.7A1.463,1.463,0,0,1,24,13.485m1.387,6.6V31.894H30.83v2.281H16.763V31.894h6.345V22.363H18.843v-2.28Z" transform="translate(1151 581)" fill="#00b0f0"/>
  	  </g>
  	 </symbol>
     <symbol id="cancel-circle" viewBox="0 0 48 48">
     	  <path d="M24,2c13.037,0,22.5,9.252,22.5,22S37.037,46,24,46,1.5,36.747,1.5,24,10.963,2,24,2Zm0,2C12.121,4,3.5,12.412,3.5,24S12.121,44,24,44s20.5-8.411,20.5-20S35.879,4,24,4Z" transform="translate(-0.5 0)" fill="#00b0f0"/>
          <path d="M29.752,16.985l1.4,1.431L25.431,24l5.718,5.584-1.4,1.432L24,25.4l-5.752,5.618-1.4-1.432L22.569,24l-5.717-5.584,1.4-1.431L24,22.6Z" transform="translate(-0.5 0)" fill="#00b0f0"/>
     </symbol>
     <symbol id="add-circle" viewBox="0 0 48 48">
     	  <path d="M24,2c13.037,0,22.5,9.252,22.5,22S37.037,46,24,46,1.5,36.747,1.5,24,10.963,2,24,2Zm0,2C12.121,4,3.5,12.412,3.5,24S12.121,44,24,44s20.5-8.411,20.5-20S35.879,4,24,4Z" transform="translate(-0.5 0)" fill="#00b0f0"/>
          <path d="M29.752,16.985l1.4,1.431L25.431,24l5.718,5.584-1.4,1.432L24,25.4l-5.752,5.618-1.4-1.432L22.569,24l-5.717-5.584,1.4-1.431L24,22.6Z" transform="rotate(-45 0 0) translate(-24 10)" fill="#00b0f0"/>
     </symbol>
     <symbol id="play-circle" viewBox="0 0 48 48">
          <path d="M22.5,0C35.537,0,45,9.252,45,22S35.537,44,22.5,44,0,34.747,0,22,9.463,0,22.5,0m0,2C10.621,2,2,10.412,2,22s8.621,20,20.5,20S43,33.589,43,22,34.379,2,22.5,2m8.347,19.447A1.17,1.17,0,0,0,30.4,21L18.8,14.165A1.2,1.2,0,0,0,18.2,14a1.182,1.182,0,0,0-.589.159A1.2,1.2,0,0,0,17,15.215V28.828a1.2,1.2,0,0,0,.608,1.056,1.17,1.17,0,0,0,1.192-.006l11.6-6.835a1.172,1.172,0,0,0,.45-1.595l0,0M19.03,27.39V16.663l9.107,5.364Z" transform="translate(1 2)" fill="white"/>
     </symbol>
    
    <!---------------------------------------------- 24 x 24 ------------------------------------------------------>
     <symbol id="clock" viewBox="0 0 24 24">
          <path d="M18.653,3.347A10.32,10.32,0,0,1,21.67,11,10.31,10.31,0,0,1,11,21.66,10.31,10.31,0,0,1,.33,11,10.32,10.32,0,0,1,11,.33,10.32,10.32,0,0,1,18.653,3.347ZM11,1.66A9,9,0,0,0,1.66,11,9,9,0,0,0,11,20.33,9,9,0,0,0,20.34,11,9,9,0,0,0,11,1.66Zm3.76,4.36,1.16.65-3.43,6.06a1.755,1.755,0,0,1-2.33.59L5.85,10.88,6.5,9.72l4.32,2.44a.37.37,0,0,0,.51-.14Z" fill="#001e50"/>
     </symbol>
     <symbol id="volume-mute" viewBox="0 0 24 24">
          <path d="M12.981,3.212a1.03,1.03,0,0,0-.49.124L6.161,6.762a2.057,2.057,0,0,1-.979.248H1.019A1.014,1.014,0,0,0,0,8.018v7.965A1.013,1.013,0,0,0,1.019,16.99H5.182a2.058,2.058,0,0,1,.979.248l6.33,3.427a1.026,1.026,0,0,0,1.384-.4A1,1,0,0,0,14,19.78V4.22a1.014,1.014,0,0,0-1.019-1.008ZM1.33,8.34H4.7v7.32H1.33ZM12.67,12v7.249L6.794,16.068a3.376,3.376,0,0,0-.768-.3V8.231a3.385,3.385,0,0,0,.768-.3L12.67,4.751ZM22,9.952l-.942-.939L19.01,11.06,16.965,9.013l-.942.939L18.07,12l-2.048,2.048.942.939L19.01,12.94l2.046,2.047L22,14.048,19.951,12,22,9.952Z" transform="translate(1 -0.212)"/>
     </symbol>
     <symbol id="volume-max" viewBox="0 0 24 24">
          <path d="M12.981,3.212a1.03,1.03,0,0,0-.49.124L6.161,6.762a2.057,2.057,0,0,1-.979.248H1.019A1.014,1.014,0,0,0,0,8.018v7.965A1.013,1.013,0,0,0,1.019,16.99H5.182a2.058,2.058,0,0,1,.979.248l6.33,3.427a1.026,1.026,0,0,0,1.384-.4A1,1,0,0,0,14,19.78V4.22a1.014,1.014,0,0,0-1.019-1.008ZM1.33,8.34H4.7v7.32H1.33ZM12.67,12v7.249L6.794,16.068a3.376,3.376,0,0,0-.768-.3V8.231a3.385,3.385,0,0,0,.768-.3L12.67,4.751ZM16.5,16.451l-.947-.934A5.33,5.33,0,0,0,16.991,12a5.289,5.289,0,0,0-1.439-3.52L16.5,7.54A6.7,6.7,0,0,1,18.322,12,6.625,6.625,0,0,1,16.5,16.451Zm2.8,2.187-.947-.934a8.847,8.847,0,0,0,2.343-5.714,8.88,8.88,0,0,0-2.337-5.709l.941-.941a10.164,10.164,0,0,1,2.726,6.65,10.3,10.3,0,0,1-2.726,6.648Z" transform="translate(1 -0.212)" />
     </symbol>
     <symbol id="volume-medium" viewBox="0 0 24 24">
          <path d="M12.981,3.212a1.03,1.03,0,0,0-.49.124L6.161,6.762a2.057,2.057,0,0,1-.979.248H1.019A1.014,1.014,0,0,0,0,8.018v7.965A1.013,1.013,0,0,0,1.019,16.99H5.182a2.058,2.058,0,0,1,.979.248l6.33,3.427a1.026,1.026,0,0,0,1.384-.4A1,1,0,0,0,14,19.78V4.22a1.014,1.014,0,0,0-1.019-1.008ZM1.33,8.34H4.7v7.32H1.33ZM12.67,12v7.249L6.794,16.068a3.376,3.376,0,0,0-.768-.3V8.231a3.385,3.385,0,0,0,.768-.3L12.67,4.751ZM16.5,16.451l-.947-.934A5.33,5.33,0,0,0,16.991,12a5.289,5.289,0,0,0-1.439-3.52L16.5,7.54A6.7,6.7,0,0,1,18.322,12,6.625,6.625,0,0,1,16.5,16.451Z" transform="translate(1 -0.212)"/>
     </symbol>
     <symbol id="play-icon" viewBox="0 0 24 24">
          <path d="M8.33,5.412,19.516,12,8.33,18.588V5.412ZM8.2,3.979a1.182,1.182,0,0,0-.589.159A1.2,1.2,0,0,0,7,5.194V18.807a1.2,1.2,0,0,0,.608,1.056A1.172,1.172,0,0,0,8.8,19.857L20.4,13.022a1.172,1.172,0,0,0,0-2.043L8.8,4.144a1.185,1.185,0,0,0-.6-.165Z" transform="translate(-1 0.021)" fill="#001e50"/>
     </symbol>
     <symbol id="pause-icon" viewBox="0 0 24 24">
          <path d="M6.835,4.5h1.33v15H6.835Zm9,0h1.33v15h-1.33V4.5Z" transform="translate(0.165 -0.5)"/>
     </symbol>
     <symbol id="chevron-left" viewBox="0 0 24 24">
          <path d="M12,17.813a1.655,1.655,0,0,1-1.189-.5l-9.786-10,.95-.93,9.785,10a.34.34,0,0,0,.48,0l9.784-10,.951.93-9.785,10A1.652,1.652,0,0,1,12,17.813Z" transform="translate(24.099) rotate(90)" fill="#00b0f0"/>
     </symbol>
     <symbol id="chevron-right" viewBox="0 0 24 24">
          <path d="M12,17.813a1.655,1.655,0,0,1-1.189-.5l-9.786-10,.95-.93,9.785,10a.34.34,0,0,0,.48,0l9.784-10,.951.93-9.785,10A1.652,1.652,0,0,1,12,17.813Z" transform="translate(-0.099 24) rotate(-90)" fill="#00b0f0"/>
     </symbol>
      <symbol id="chevron-down" viewBox="0 0 24 24">
          <path d="M12,17.813a1.655,1.655,0,0,1-1.189-.5l-9.786-10,.95-.93,9.785,10a.34.34,0,0,0,.48,0l9.784-10,.951.93-9.785,10A1.652,1.652,0,0,1,12,17.813Z" transform="translate(-0.025 -0.386)" fill="#00b0f0"/>
     </symbol>
      <symbol id="quit" viewBox="0 0 24 24">
          <path d="M20.956,3.985l-.942-.941L12,11.059,3.985,3.044l-.941.941L11.059,12,3.044,20.015l.941.941L12,12.941l8.014,8.015.942-.941L12.941,12Z" transform="translate(-0.044 -0.044)" fill="#00b0f0"/>
     </symbol>
     <symbol id="language" viewBox="0 0 24 24">
          <path d="M12,1.012A10.986,10.986,0,1,0,22.983,12,10.986,10.986,0,0,0,12,1.012Zm9.622,10.314H18.411a14.109,14.109,0,0,0-2.65-8.22,9.669,9.669,0,0,1,5.858,8.22ZM11.332,2.451v8.875H6.911C7.09,6.434,9.178,3.042,11.332,2.451Zm0,10.205v8.889c-2.156-.592-4.246-3.989-4.421-8.889Zm1.33,8.889V12.656h4.42C16.907,17.556,14.818,20.953,12.662,21.545Zm0-10.219V2.451c2.154.592,4.242,3.983,4.42,8.875Zm-4.43-8.22a14.109,14.109,0,0,0-2.65,8.22H2.374a9.671,9.671,0,0,1,5.858-8.22Zm-5.858,9.55H5.582a14.12,14.12,0,0,0,2.65,8.234,9.669,9.669,0,0,1-5.858-8.234ZM15.761,20.89a14.12,14.12,0,0,0,2.65-8.234H21.62a9.672,9.672,0,0,1-5.859,8.234Z" transform="translate(-0.01 -0.013)" fill="#00b0f0"/>
     </symbol>
     <symbol id="bulletpoint" viewBox="0 0 24 24">
          <rect id="_24x24" data-name="24x24" width="24" height="24" fill="none"/>
  		  <rect id="Rectangle_1955" data-name="Rectangle 1955" width="24" height="1.331" transform="translate(0 11.29)" fill="#001e50"/>
     </symbol>
     <symbol id="menu" viewBox="0 0 24 24">
          <path d="M4,11.736A1.648,1.648,0,0,1,5.68,10a1.632,1.632,0,0,1,1.68,1.736A1.64,1.64,0,0,1,5.68,13.5,1.655,1.655,0,0,1,4,11.736Zm5.964,0a1.681,1.681,0,1,1,3.36,0,1.682,1.682,0,1,1-3.36,0Zm6.02,0a1.681,1.681,0,1,1,3.36,0,1.682,1.682,0,1,1-3.36,0Z" fill="#00b0f0"/>
     </symbol>
     <symbol id="search" viewBox="0 0 24 24">
          <rect id="Rectangle_41" data-name="Rectangle 41" width="24" height="24" fill="#00b0f0" opacity="0"/>
  		  <path id="Shape" d="M21.5,20.53,16.69,15.6a9.38,9.38,0,0,0,2-5.79A9.19,9.19,0,0,0,9.59.5,9.2,9.2,0,0,0,.5,9.81a9.2,9.2,0,0,0,9.09,9.31,8.94,8.94,0,0,0,6.19-2.51l4.77,4.89ZM1.84,9.81A7.85,7.85,0,0,1,9.59,1.88a7.85,7.85,0,0,1,7.74,7.93,7.85,7.85,0,0,1-7.74,7.94A7.86,7.86,0,0,1,1.84,9.81Z" transform="translate(1.501 1.5)" fill="#00b0f0"/>
  	 </symbol>
     
    <!---------------------------------------------- other sizes ------------------------------------------------------>
   <symbol id="brand-icon" viewBox="0 0 270 270">
          <path d="M135,257.556C67.878,257.556,12.822,202.122,12.822,135a119.715,119.715,0,0,1,7.73-42.8L92.011,236.061c.942,1.9,2.074,3.394,4.336,3.394s3.51-1.534,4.336-3.394l32.808-73.721c.378-.753.756-1.509,1.509-1.509s1.131.756,1.509,1.509l32.805,73.721c.829,1.858,2.076,3.394,4.339,3.394s3.4-1.5,4.336-3.394L249.448,92.2a119.714,119.714,0,0,1,7.73,42.8c0,67.122-55.056,122.556-122.178,122.556m0-150.271c-.753,0-1.131-.756-1.509-1.509L95.029,19.421a117.987,117.987,0,0,1,79.942,0l-38.462,86.354c-.378.753-.756,1.509-1.509,1.509M95.971,195.9c-.756,0-1.131-.753-1.509-1.507L32.241,69.009A126.352,126.352,0,0,1,76.172,27.716l44.685,99.554a3.155,3.155,0,0,0,3.4,2.263h21.492a3.155,3.155,0,0,0,3.4-2.263l44.685-99.554a126.328,126.328,0,0,1,43.932,41.294L175.538,194.395c-.378.753-.756,1.507-1.509,1.507s-1.131-.753-1.507-1.507l-23.379-53.549a3.152,3.152,0,0,0-3.4-2.263H124.254a3.152,3.152,0,0,0-3.4,2.263L97.481,194.395c-.378.753-.756,1.507-1.509,1.507M135,270A135,135,0,1,0,0,135,134.863,134.863,0,0,0,135,270" fill="#001e50"/>
   </symbol>
   <symbol id="media" viewBox="0 0 72 72">
          <path d="M36,2C55.7,2,70,15.878,70,35S55.7,68,36,68,2,54.121,2,35,16.3,2,36,2m0-2C15.141,0,0,14.72,0,35S15.141,70,36,70,72,55.281,72,35,56.86,0,36,0M54.411,33.71a2.624,2.624,0,0,0-1-1V32.7L27.293,17.323a2.637,2.637,0,0,0-2.674-.014,2.705,2.705,0,0,0-1.369,2.377V50.314a2.706,2.706,0,0,0,1.369,2.377,2.64,2.64,0,0,0,2.676-.015L53.407,37.3a2.636,2.636,0,0,0,1-3.59m-1.725,1.573a.64.64,0,0,1-.292.292l0,0L26.284,50.95a.671.671,0,0,1-.341.1.662.662,0,0,1-.33-.092.712.712,0,0,1-.363-.642V19.686a.708.708,0,0,1,.363-.641.632.632,0,0,1,.665,0L52.394,34.428a.638.638,0,0,1,.292.855" transform="translate(0 1)" fill="#00b0f0"/>
   </symbol>
    <symbol id="qr-code" viewBox="0 0 35 35">
        <g id="Group_4036" data-name="Group 4036" transform="translate(-2812 1194)">
		<g id="Rectangle_49" data-name="Rectangle 49" transform="translate(2831 -1175)" fill="none" stroke="#ffffff" stroke-width="2">
		  <rect width="16" height="16" stroke="none"/>
		  <rect x="1" y="1" width="14" height="14" fill="none"/>
		</g>
	  </g>
	  <g id="Group_4037" data-name="Group 4037" transform="translate(-2831 1194)">
		<g id="Rectangle_49-2" data-name="Rectangle 49" transform="translate(2831 -1175)" fill="none" stroke="#ffffff" stroke-width="2">
		  <rect width="16" height="16" stroke="none"/>
		  <rect x="1" y="1" width="14" height="14" fill="none"/>
		</g>
		<rect id="Rectangle_50" data-name="Rectangle 50" width="6" height="6" transform="translate(2836 -1170)" fill="#ffffff"/>
	  </g>
	  <g id="Group_90" data-name="Group 90" transform="translate(-2812 1175)">
		<g id="Rectangle_49-3" data-name="Rectangle 49" transform="translate(2831 -1175)" fill="none" stroke="#ffffff" stroke-width="2">
		  <rect width="16" height="16" stroke="none"/>
		  <rect x="1" y="1" width="14" height="14" fill="none"/>
		</g>
		<rect id="Rectangle_50-2" data-name="Rectangle 50" width="6" height="6" transform="translate(2836 -1170)" fill="#ffffff"/>
	  </g>
	  <g id="Group_89" data-name="Group 89" transform="translate(-2831 1175)">
		<rect id="Frame" width="16" height="16" transform="translate(2831 -1175)" fill="none"/>
		<rect id="Rectangle_1910" data-name="Rectangle 1910" width="6" height="6" transform="translate(2841 -1165)" fill="#ffffff"/>
		<rect id="Rectangle_50-3" data-name="Rectangle 50" width="10" height="10" transform="translate(2831 -1175)" fill="#ffffff"/>
		<rect id="Rectangle_1911" data-name="Rectangle 1911" width="3" height="6" transform="translate(2844 -1175)" fill="#ffffff"/>
		<rect id="Rectangle_1912" data-name="Rectangle 1912" width="6" height="3" transform="translate(2831 -1162)" fill="#ffffff"/>
	  </g>
   </symbol>
   <symbol viewBox="0 0 12 12" id="forward-xsmall">
		<path d="M10.243,7.293,6,3.05l-.707.707L9.536,8,5.293,12.243,6,12.95l4.243-4.243L10.95,8Z" transform="translate(-2.293 -2.05)" fill="#000000"/>
	</symbol>
   

   <!---------------------------------------------- volkswagen icons ------------------------------------------------------>

<!--      <symbol viewBox="0 0 24.341 24" id="motor">-->
<!--          <rect id="Rechteck_92" data-name="Rechteck 92" width="24" height="24" fill="none"/>-->
<!--          <path id="Shape" d="M22.065,5.745a1.463,1.463,0,0,0,1.529-1.382V2.179A1.459,1.459,0,0,0,22.069.8H16.807a1.464,1.464,0,0,0-1.53,1.382V4.364a1.464,1.464,0,0,0,1.53,1.382h2.01V8.426a2.264,2.264,0,0,0-1.945,1.909H5.082V5.745H7.049A1.464,1.464,0,0,0,8.58,4.364V2.179A1.459,1.459,0,0,0,7.054.8H1.785A1.464,1.464,0,0,0,.254,2.183V4.364A1.464,1.464,0,0,0,1.785,5.745H3.752v10.51H1.785A1.463,1.463,0,0,0,.254,17.636v2.182A1.463,1.463,0,0,0,1.785,21.2H7.049A1.463,1.463,0,0,0,8.58,19.818V17.632a1.459,1.459,0,0,0-1.526-1.377H5.082v-4.59h11.79a2.264,2.264,0,0,0,1.945,1.909v2.681h-2.01a1.463,1.463,0,0,0-1.53,1.381v2.182a1.463,1.463,0,0,0,1.53,1.381h5.259a1.463,1.463,0,0,0,1.529-1.381V17.632a1.459,1.459,0,0,0-1.525-1.377H20.146v-2.7A2.362,2.362,0,0,0,22.066,11a2.307,2.307,0,0,0-1.92-2.557v-2.7ZM1.584,4.415l0-2.284H7.25l0,2.284ZM7.25,17.585l.005,2.283H1.584l0-2.283Zm15.015,0,0,2.283H16.606l0-2.283ZM20.736,11c0,.91-.389,1.3-1.3,1.3s-1.3-.388-1.3-1.3.389-1.3,1.3-1.3c.96,0,1.3.34,1.3,1.3ZM22.27,4.415H16.606l0-2.284h5.662" transform="translate(0.746 1.201)" fill="#001e50"/>-->
<!--      </symbol>-->
<!--      <symbol viewBox="0 0 24 24" id="efficiency">-->
<!--           <rect id="Rechteck_21" data-name="Rechteck 21" width="24" height="24" fill="none"/>-->
<!--           <path id="Shape" d="M13.84,1.68H9.34V.35h4.5Zm2,2.66H9.34V5.67h6.5Zm2,4H9.34V9.67h8.5Zm2,4H9.34v1.33h10.5ZM.55,7.52l2,.75L1.07,13.06a.26.26,0,0,0,.45.23L5.83,7.47a.6.6,0,0,0-.27-.92l-2.14-.8L4.65.86a.251.251,0,0,0-.46-.2l-3.93,6a.61.61,0,0,0,.29.86Z" transform="translate(1.819 5.65)" fill="#001e50"/>-->
<!--      </symbol>-->
<!--      <symbol viewBox="0 0 24 24" id="emission">-->
<!--           <rect id="Rechteck_22" data-name="Rechteck 22" width="24" height="24" transform="translate(0)" fill="none"/>-->
<!--           <g id="emission" transform="translate(-0.374 2.672)">-->
<!--                <path id="Path" d="M7.88,7.5a3,3,0,0,1,1.39.35L9,8.61a2.28,2.28,0,0,0-1.13-.26c-.83,0-1.36.82-1.36,2s.53,2,1.36,2A2.28,2.28,0,0,0,9,12.07l.26.76a3,3,0,0,1-1.39.35C6.35,13.18,5.5,12,5.5,10.34S6.35,7.5,7.88,7.5Z" fill="#001e50"/>-->
<!--                <path id="Shape" d="M12.21,13.18c-1.63,0-2.36-1.25-2.36-2.84s.73-2.84,2.36-2.84,2.35,1.25,2.35,2.84S13.83,13.18,12.21,13.18Zm0-.85c1,0,1.33-.88,1.33-2s-.36-2-1.33-2-1.34.88-1.34,2S11.24,12.33,12.21,12.33Z" fill="#001e50"/>-->
<!--                <path id="Path-2" data-name="Path" d="M16.87,13.2h1.46V14H15.52v-.74L17,12a.68.68,0,0,0,.27-.57.45.45,0,0,0-.47-.49,1.15,1.15,0,0,0-.79.45l-.53-.64a1.77,1.77,0,0,1,1.37-.66,1.26,1.26,0,0,1,1.4,1.27,1.59,1.59,0,0,1-.7,1.24l-.71.59Z" fill="#001e50"/>-->
<!--                <path id="Shape-2" data-name="Shape" d="M17,18.67H6.5A6.16,6.16,0,0,1,2,8.34a2.94,2.94,0,0,1-.12-.84A3.18,3.18,0,0,1,5,4.33a8.16,8.16,0,0,1,14.77,1.6A6.67,6.67,0,0,1,17,18.67ZM5,5.67A1.83,1.83,0,0,0,3.17,7.5a1.76,1.76,0,0,0,.16.74l.19.43L3.18,9a4.76,4.76,0,0,0-1.51,3.5A4.83,4.83,0,0,0,6.5,17.33H17A5.33,5.33,0,0,0,19,7l-.31-.12-.09-.31A6.83,6.83,0,0,0,5.94,5.34l-.22.42-.46-.07Z" fill="#001e50"/>-->
<!--           </g> -->
<!--      </symbol>-->
<!--      <symbol viewBox="0 0 24 24" id="gear">-->
<!--            <rect id="Rechteck_91" data-name="Rechteck 91" width="24" height="24" fill="none"/>-->
<!--            <path id="Path" d="M18.24,2.5a2,2,0,1,0-2.67,1.88v7a.5.5,0,0,1-.4.5H9.9V4.38a2,2,0,1,0-1.33,0v7.45H2.9V4.38a2,2,0,1,0-1.33,0V19.62a2,2,0,1,0,1.33,0V13.16H8.57v6.46a2,2,0,1,0,1.33,0V13.17h3.68a12.81,12.81,0,0,0,1.81,0,1.82,1.82,0,0,0,1.51-1.81v-7A2,2,0,0,0,18.24,2.5Z" transform="translate(2.765 -0.494)" fill="#001e50"/>-->
<!--      </symbol>-->
<!--      <symbol viewBox="0 0 24 24" id="fuel">-->
<!--            <rect id="Rechteck_94" data-name="Rechteck 94" width="24" height="24" fill="none"/>-->
<!--            <path id="Shape" d="M10.05.85l5.65,8a8.2,8.2,0,1,1-13.4,0l5.65-8ZM9.36,2.17H8.64L3.39,9.62a6.86,6.86,0,1,0,11.22,0ZM4.75,10.58l1.09.76a3.84,3.84,0,0,0,.43,5l-.94.91A5.18,5.18,0,0,1,4.75,10.58Z" transform="translate(3.206 1.15)" fill="#001e50"/>-->
<!--      </symbol>-->
<!--      <symbol viewBox="0 0 24 24" id="power">-->
<!--            <rect id="Rechteck_23" data-name="Rechteck 23" width="24" height="24" fill="none"/>-->
<!--            <g id="performance" transform="translate(-0.33 4.67)">-->
<!--                <path id="Path" d="M12,.33C5.57.33.33,6,.33,13H1.67C1.67,6.75,6.3,1.67,12,1.67S22.33,6.75,22.33,13h1.34C23.67,6,18.43.33,12,.33Z" fill="#001e50"/>-->
<!--                <path id="Path-2" data-name="Path" d="M17,7l-4.56,4.55A1.45,1.45,0,0,0,12,11.5,1.5,1.5,0,1,0,13.5,13a1.45,1.45,0,0,0-.08-.47L18,8Z" fill="#001e50"/>-->
<!--            </g>-->
<!--      </symbol>-->
<!--      <symbol viewBox="0 0 24 24" id="consumption">-->
<!--          <rect id="Rechteck_93" data-name="Rechteck 93" width="24" height="24" fill="none"/>-->
<!--          <path id="Shape" d="M22.984,14.433c-.018-3.742-.043-8.866-.015-9.724a3.358,3.358,0,0,0-.8-2.773,3.8,3.8,0,0,0-2.813-.923V2.28c1.074.041,1.554.212,1.831.489a2.306,2.306,0,0,1,.48,1.754,3.226,3.226,0,0,0,0,6.2c0,1.234.011,2.517.017,3.713.01,1.954.007,3.6.016,4.205.028,1.783-1.142,1.871-1.5,1.871-.383,0-1.487-.009-1.494-1.79l-.011-.683a3.144,3.144,0,0,0-.874-2.349,2.791,2.791,0,0,0-1.89-.79V3.069A2.039,2.039,0,0,0,13.9,1.03H3.043A2.043,2.043,0,0,0,1,3.073v19.94H15.937V16.175c.287.01,1.461.156,1.464,1.88l.011.687a3.013,3.013,0,0,0,.82,2.271,2.57,2.57,0,0,0,1.982.819,2.478,2.478,0,0,0,1.962-.787,3.014,3.014,0,0,0,.824-2.4C23,18.035,22.993,16.392,22.984,14.433ZM14.6,21.683H2.332V3.07a.713.713,0,0,1,.711-.711H13.894a.713.713,0,0,1,.711.711ZM20.66,7.625a1.9,1.9,0,0,1,1-1.675V9.3a1.906,1.906,0,0,1-1-1.678ZM8.518,5.236a1.76,1.76,0,1,0,1.243.526,1.772,1.772,0,0,0-1.243-.526Z" fill="#001e50"/>-->
<!--      </symbol>-->
<!--      <symbol id="qr-code" viewBox="0 0 64 64">-->
<!--          <g fill="none"><path d="M34 34h7v7h-7z"/><path d="M35 35h5v5h-5zM34 43h7v7h-7z"/><path d="M35 44h5v5h-5zM43 34h7v7h-7z"/><path d="M44 35h5v5h-5zM43 43h7v7h-7z"/><path d="M44 44h5v5h-5zM15 34h16v16H15z"/><path d="M16 35h14v14H16z"/><g><path d="M19 38h8v8h-8z"/><path d="M20 39h6v6h-6z"/></g><g><path d="M15 15h16v16H15z"/><path d="M16 16h14v14H16z"/></g><g><path d="M19 19h8v8h-8z"/><path d="M20 20h6v6h-6z"/></g><g><path d="M34 15h16v16H34z"/><path d="M35 16h14v14H35z"/></g><g><path d="M38 19h8v8h-8z"/><path d="M39 20h6v6h-6z"/></g></g>-->
<!--      </symbol>-->
<!--      <symbol id="maximum-speed" viewBox="0 0 24 24">-->
<!--        <rect id="Rectangle_105" data-name="Rectangle 105" width="24" height="24" fill="none"/>-->
<!--        <path id="Path_11" data-name="Path 11" d="M18.044,259.876l-2.638,2.638a1.5,1.5,0,1,1-.92-.92l2.638-2.638ZM14,253a9.965,9.965,0,0,0-7.136,3H8.856a8.7,8.7,0,1,1-1.14,13h-1.7A10,10,0,1,0,14,253Zm-4,11.85H2v1.3h8Zm-4-3H0v1.3H6Zm2-3H3v1.3H8Z" transform="translate(0 -251)" fill="#001e50"/>-->
<!--      </symbol>-->
<!--      <symbol id="mileage" viewBox="0 0 22.3 22.301">-->
<!--          <path id="Path_6" data-name="Path 6" d="M23.15,135a11.15,11.15,0,1,1-19.034-7.885l.919.92a9.85,9.85,0,1,0,7.615-2.864V128.5h-1.3v-4.65H12A11.163,11.163,0,0,1,23.15,135ZM10.5,135a1.5,1.5,0,1,0,1.5-1.5,1.479,1.479,0,0,0-.491.089l-3.55-3.549-.918.92,3.548,3.549A1.479,1.479,0,0,0,10.5,135Z" transform="translate(-0.85 -123.85)" fill="#001e50"/>-->
<!--      </symbol>-->
<!--      <symbol id="guarantee" viewBox="0 0 24 24">-->
<!--          <rect id="Rectangle_98" data-name="Rectangle 98" width="24" height="24" fill="none"/>-->
<!--          <path id="Path_4" data-name="Path 4" d="M19.2,64.15H4.8A1.953,1.953,0,0,1,2.85,62.2V43.8A1.953,1.953,0,0,1,4.8,41.85h6.125v1.3H4.8a.65.65,0,0,0-.65.65V62.2a.65.65,0,0,0,.65.65H19.2a.65.65,0,0,0,.65-.65V55.106h1.3V62.2A1.953,1.953,0,0,1,19.2,64.15Zm1.585-11.066a.649.649,0,0,0,.365-.584v-10a.65.65,0,0,0-.65-.65h-7a.65.65,0,0,0-.65.65v10a.65.65,0,0,0,1.049.513L17,50.6l3.1,2.416a.645.645,0,0,0,.4.137A.653.653,0,0,0,20.785,53.084ZM16.6,49.26,14.15,51.169V43.15h5.7v8.019L17.4,49.26a.646.646,0,0,0-.8,0ZM18,55.85H6v1.3H18Zm-5,3H6v1.3h7Z" transform="translate(0.15 -40.85)" fill="#001e50"/>-->
<!--      </symbol>-->
<!--      <symbol id="first-registration" viewBox="0 0 24 24"> &lt;!&ndash; Erstanmeldung/Registrierungsdatum (Baujahr?) &ndash;&gt;-->
<!--          <rect id="Rectangle_99" data-name="Rectangle 99" width="24" height="24" fill="none"/>-->
<!--          <path id="Path_5" data-name="Path 5" d="M19.15,85V83h-1.3v2H6.15V83H4.85v2H1v17a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V85ZM4.85,86.3V89h1.3V86.3h11.7V89h1.3V86.3H21.7v4.55H2.3V86.3ZM20,103.7H4A1.7,1.7,0,0,1,2.3,102V92.15H21.7V102A1.7,1.7,0,0,1,20,103.7Zm-7.186-3.792h1.211v1.064H10.137V99.908h1.4V96h-.019l-1.091.34h-.11V95.249l1.541-.514h.953Z" transform="translate(0 -82)" fill="#001e50"/>-->
<!--      </symbol>-->
<!--      <symbol id="engine" viewBox="0 0 24 24">-->
<!--          <rect id="Rectangle_19" data-name="Rectangle 19" width="24" height="24" fill="none"/>-->
<!--          <path id="Shape" d="M21.59,8.15v3h-1.3a.7.7,0,0,1-.7-.7V7.98a1,1,0,0,0-.95-1H12.75v-2h2.94V3.7H8.36V5.03h3.06v2H5.28a1,1,0,0,0-1,1V11.4h-2v-3H1v3H1v1.33H1v3H2.33v-3h2v2.65a1.49,1.49,0,0,0,.52,1.13l3.93,3.42a1.481,1.481,0,0,0,1,.37h8.88a1,1,0,0,0,.95-1V16.75a.7.7,0,0,1,.7-.7h1.3v3.1H23v-11Zm-1.3,6.57a2,2,0,0,0-2,2v2.26H9.76a.21.21,0,0,1-.11,0L5.72,15.47a.16.16,0,0,1-.06-.13v-7h12.6v2.14a2,2,0,0,0,2,2h1.3v2.21Z" transform="translate(0 0.3)" fill="#001e50"/>-->
<!--      </symbol>-->
<!--      <symbol id="next-inspection" viewBox="0 0 24 24">-->
<!--          <rect id="Rectangle_101" data-name="Rectangle 101" width="24" height="24" fill="none"/>-->
<!--          <path id="Path_7" data-name="Path 7" d="M19.2,187.15H4.8a1.953,1.953,0,0,1-1.95-1.95V165.85H6v1.3H4.15V185.2a.65.65,0,0,0,.65.65H19.2a.65.65,0,0,0,.65-.65V167.15H18v-1.3h3.15V185.2A1.953,1.953,0,0,1,19.2,187.15Zm-3.05-22.3H7.85v4.3h8.3Zm-7,1.3h5.7v1.7H9.15Zm2.357,14.2,4.906-6.942-1.062-.75-4.53,6.41L8.493,177.42l-.75,1.062L10.6,180.5a.649.649,0,0,0,.9-.156Z" transform="translate(0.15 -163.85)" fill="#001e50"/>-->
<!--      </symbol>-->
<!--      <symbol id="calendar" viewBox="0 0 24 24">-->
<!--          <rect id="Rectangle_20" data-name="Rectangle 20" width="24" height="24" fill="none"/>-->
<!--          <path id="Combined-Shape" d="M5,12.5a1,1,0,0,1,0,2,1,1,0,1,1,0-2Zm4,0a1,1,0,1,1-1,1A.965.965,0,0,1,9,12.5Zm4,0a1,1,0,1,1-1,1A.965.965,0,0,1,13,12.5Zm4,0a1,1,0,1,1-1,1A.965.965,0,0,1,17,12.5Zm-12,4a1,1,0,0,1,0,2,1,1,0,0,1,0-2Zm4,0a1,1,0,1,1-1,1A.965.965,0,0,1,9,16.5Zm4,0a1,1,0,1,1-1,1A.965.965,0,0,1,13,16.5Zm4,0a1,1,0,1,1-1,1A.965.965,0,0,1,17,16.5Zm2,4.17A1.672,1.672,0,0,0,20.67,19V10.165H1.33V19A1.672,1.672,0,0,0,3,20.67ZM4,3.327H1.33V8.835H20.67V3.327H18V6h-1.33V3.327H5.33V6H4ZM18,2h4V19a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V2H4V0H5.33V2H16.666V0H18Z" transform="translate(1 1)" fill="#001e50"/>-->
<!--      </symbol>-->
<!--      <symbol id="acceleration" viewBox="0 0 24 24">-->
<!--          <path id="Path_9" data-name="Path 9" d="M12.65,210.883v-1.664H15.5v-1.3h-7v1.3h2.85v1.664a9.15,9.15,0,1,0,1.3,0ZM12,227.85A7.85,7.85,0,1,1,19.85,220,7.859,7.859,0,0,1,12,227.85Zm4.044-10.974-2.638,2.638a1.5,1.5,0,1,1-.92-.919l2.638-2.639ZM3.315,213.437l-.919-.92L4.518,210.4l.919.919Z" transform="translate(0 -206)" fill="#001e50"/>-->
<!--      </symbol>-->
<!--      <symbol id="electric-range" viewBox="0 0 24 24">-->
<!--          <path id="Combined-Shape" d="M18.824,7l.06,0c.315.017.594.213,2.632,2.5h0l-.994.884c-.447-.5-.79-.879-1.063-1.174h0v8.222a2.348,2.348,0,1,1-1.33,0h0V9.245c-.26.292-.584.658-1,1.145h0l-1.007-.869C18.291,7,18.418,7,18.793,7ZM9.151,2a1.382,1.382,0,0,1,1.38,1.381h0v1.08h.451A2.23,2.23,0,0,1,13.21,6.689h0V19.767a2.23,2.23,0,0,1-2.228,2.228H4.341a2.231,2.231,0,0,1-2.228-2.228h0V6.689A2.231,2.231,0,0,1,4.341,4.461h.436V3.381A1.383,1.383,0,0,1,6.158,2H9.151ZM18.8,18.66a1.05,1.05,0,0,0-.229.025,1.02,1.02,0,1,0,.229-.025ZM10.982,5.791H4.341a.9.9,0,0,0-.9.9h0V19.767a.9.9,0,0,0,.9.9h6.641a.9.9,0,0,0,.9-.9h0V6.689a.9.9,0,0,0-.9-.9ZM8.48,8.684a.187.187,0,0,1,.33.152h0l-.9,3.505,1.527.574a.431.431,0,0,1,.2.66h0L6.551,17.742a.18.18,0,0,1-.323-.158h0L7.3,14.149,5.87,13.611a.438.438,0,0,1-.215-.624h0ZM18.791,2a1.766,1.766,0,1,1-1.762,1.77A1.766,1.766,0,0,1,18.791,2ZM9.2,3.33H6.158V4.461H9.2Z" transform="translate(-0.113)" fill="#001e50"/>-->
<!--      <symbol id="consumption-electric" viewBox="0 0 24 24">-->
<!--          <path id="Combined-Shape" d="M13.53,1a1.434,1.434,0,0,1,1.4,1.422h0V3.686H16.17a2.368,2.368,0,0,1,2.365,2.365h0V20.275A2.368,2.368,0,0,1,16.17,22.64H7.553a2.368,2.368,0,0,1-2.365-2.365h0V6.051A2.368,2.368,0,0,1,7.553,3.686h1.24V2.423A1.434,1.434,0,0,1,10.192,1H13.53Zm2.64,4.015H7.553A1.036,1.036,0,0,0,6.518,6.051h0V20.275A1.036,1.036,0,0,0,7.553,21.31H16.17A1.036,1.036,0,0,0,17.2,20.275h0V6.051A1.036,1.036,0,0,0,16.17,5.016ZM12.942,7.7a.223.223,0,0,1,.394.18h0l-1.071,4.191,1.826.686a.514.514,0,0,1,.24.789h0l-3.695,4.98a.232.232,0,0,1-.288.1.225.225,0,0,1-.1-.288h0l1.286-4.1-1.715-.643a.522.522,0,0,1-.256-.746h0ZM13.5,2.331h-3.3a.1.1,0,0,0-.084.092h0V3.675H13.6V2.423a.1.1,0,0,0-.094-.092Z" transform="translate(-5.188 -0.001)" fill="#001e50"/>-->
<!--	  </symbol>-->
<!--      <symbol id="fuel-hybrid" viewBox="0 0 24 24">-->
<!--          <path id="Combined-Shape" d="M11.58,1.53l1.51,2.09L12,4.38,10.89,2.91h-.72L4.92,10.36a6.86,6.86,0,1,0,12.2,5.86h-.64a2.89,2.89,0,0,1-2.91-2.84h0V10.33h-.82V9h2.14V5.5h1.33V9h2.56V5.5h1.33V9h2.14l-.02,1.28h-.82v3.03a2.89,2.89,0,0,1-2.91,2.84,8.31,8.31,0,0,1-2.17,3.9A8.2,8.2,0,0,1,3.83,9.53h0l5.65-8ZM5.75,11.58l1.09.72a3.84,3.84,0,0,0,.43,5h0l-.94.95a5.18,5.18,0,0,1-.58-6.67Zm14.39-1.3h-5.2v3.05a1.54,1.54,0,0,0,1.58,1.5h2a1.54,1.54,0,0,0,1.58-1.5h0Z" transform="translate(-0.339 -0.53)" fill="#001e50"/>-->
<!--	  </symbol>-->
<!--      <symbol id="fuel-electric" viewBox="0 0 24 24">-->
<!--          <path id="Combined-Shape" d="M8.67.68V5.83h6.66V.68h1.34V5.83H20.5V7.16H19.11v6.65a5.21,5.21,0,0,1-5,5.36H12.64V23.5H11.3V19.17H9.83a5.21,5.21,0,0,1-5-5.36h0V7.16H3.5V5.83H7.33V.68Zm9.16,6.48H6.17v6.67a3.86,3.86,0,0,0,3.69,4h4.28a3.86,3.86,0,0,0,3.69-4h0ZM15,12.33v1.33H9V12.33Zm1-3v1.33H8V9.33Z" transform="translate(0.5 -0.68)" fill="#001e50"/>-->
<!--	  </symbol>-->
<!--      <symbol id="fuel-gas" viewBox="0 0 24 24">-->
<!--     	<rect id="Rectangle_1899" data-name="Rectangle 1899" width="24" height="24" fill="none"/>-->
<!--  		<path id="Shape" d="M21.75,2.83H12.88a8.81,8.81,0,0,0-8.8,8.79v.76a8.73,8.73,0,0,0,2.13,5.71l-.8.81a4.89,4.89,0,0,1-3.47,1.43H1v1.34h.94a6.19,6.19,0,0,0,4.41-1.83l.81-.8a8.74,8.74,0,0,0,5.72,2.13h.74a8.81,8.81,0,0,0,8.8-8.79V3.5Zm-.67,9.55a7.46,7.46,0,0,1-7.46,7.45h-.74A7.42,7.42,0,0,1,8.1,18.1L11,15.17h5.5V13.83H12.36l5.36-5.36-.94-.94-5.61,5.61V8.5H9.83v6L7.15,17.15a7.42,7.42,0,0,1-1.73-4.77v-.76a7.46,7.46,0,0,1,7.46-7.45h8.2Z" transform="translate(1 0.17)" fill="#001e50"/>-->
<!--     </symbol>-->


<!--      &lt;!&ndash;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45; general icons &#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&ndash;&gt;-->

<!--      <symbol id="info-icon" viewBox="0 0 24 24">-->
<!--          <path id="info" d="M12,1A10.639,10.639,0,0,1,23,12,10.639,10.639,0,0,1,12,23,10.639,10.639,0,0,1,1,12,10.639,10.639,0,0,1,12,1Zm0,1.33A9.353,9.353,0,0,0,2.33,12,9.353,9.353,0,0,0,12,21.67,9.353,9.353,0,0,0,21.67,12,9.353,9.353,0,0,0,12,2.33Zm.783,7.677v5.649h2.6v1.33H8.419v-1.33h3.034V11.337H9.414v-1.33ZM12,6.493a.746.746,0,0,1,.774.811.775.775,0,1,1-1.548,0A.746.746,0,0,1,12,6.493Z" fill="#001e50"/>-->
<!--      </symbol>-->
<!--      <symbol id="info-icon-filled" viewBox="0 0 24 24">-->
<!--          <path id="info-filled" d="M12,1A10.639,10.639,0,0,1,23,12,10.639,10.639,0,0,1,12,23,10.639,10.639,0,0,1,1,12,10.639,10.639,0,0,1,12,1Zm.783,9.007H9.414v1.33h2.039v4.319H8.419v1.33h6.967v-1.33h-2.6ZM12,6.493a.746.746,0,0,0-.774.811.775.775,0,1,0,1.548,0A.746.746,0,0,0,12,6.493Z" fill="#001e50"/>-->
<!--      </symbol>-->
<!--      <symbol id="play-icon" viewBox="0 0 24 24">-->
<!--          <path id="Shape" d="M12,2.33A9.353,9.353,0,0,1,21.67,12,9.353,9.353,0,0,1,12,21.67,9.353,9.353,0,0,1,2.33,12,9.353,9.353,0,0,1,12,2.33M12,1A10.653,10.653,0,0,0,1,12,10.653,10.653,0,0,0,12,23,10.653,10.653,0,0,0,23,12,10.653,10.653,0,0,0,12,1ZM9.967,8.77,15.451,12,9.967,15.23V8.77M9.383,7a.736.736,0,0,0-.367.1.747.747,0,0,0-.379.658v8.486a.748.748,0,0,0,.379.658.73.73,0,0,0,.741,0l7.234-4.26a.731.731,0,0,0,0-1.274L9.757,7.1A.735.735,0,0,0,9.383,7Z" transform="translate(0.006 0.006)"/>-->
<!--      </symbol>-->
<!--      <symbol id="player-play-icon" viewBox="0 0 24 24">-->
<!--		  <path id="play" d="M8.33,5.412,19.516,12,8.33,18.588V5.412ZM8.2,3.979a1.182,1.182,0,0,0-.589.159A1.2,1.2,0,0,0,7,5.194V18.807a1.2,1.2,0,0,0,.608,1.056A1.172,1.172,0,0,0,8.8,19.857L20.4,13.022a1.172,1.172,0,0,0,0-2.043L8.8,4.144a1.185,1.185,0,0,0-.6-.165Z" transform="translate(-1 0.021)"/>-->
<!--	  </symbol>-->
<!--      <symbol id="player-pause-icon" viewBox="0 0 24 24">-->
<!--		  <path id="pause" d="M6.835,4.5h1.33v15H6.835Zm9,0h1.33v15h-1.33V4.5Z" transform="translate(0.165 -0.5)"/>-->
<!--	  </symbol>-->
<!--      <symbol id="player-volume-mute-icon" viewBox="0 0 24 24">-->
<!--  		  <path id="Combined-Shape" d="M12.981,3.212a1.03,1.03,0,0,0-.49.124L6.161,6.762a2.057,2.057,0,0,1-.979.248H1.019A1.014,1.014,0,0,0,0,8.018v7.965A1.013,1.013,0,0,0,1.019,16.99H5.182a2.058,2.058,0,0,1,.979.248l6.33,3.427a1.026,1.026,0,0,0,1.384-.4A1,1,0,0,0,14,19.78V4.22a1.014,1.014,0,0,0-1.019-1.008ZM1.33,8.34H4.7v7.32H1.33ZM12.67,12v7.249L6.794,16.068a3.376,3.376,0,0,0-.768-.3V8.231a3.385,3.385,0,0,0,.768-.3L12.67,4.751ZM22,9.952l-.942-.939L19.01,11.06,16.965,9.013l-.942.939L18.07,12l-2.048,2.048.942.939L19.01,12.94l2.046,2.047L22,14.048,19.951,12,22,9.952Z" transform="translate(1 -0.212)"/>-->
<!--	  </symbol>-->
<!--      <symbol id="player-volume-medium-icon" viewBox="0 0 24 24">-->
<!--  		  <path id="Combined-Shape" d="M12.981,3.212a1.03,1.03,0,0,0-.49.124L6.161,6.762a2.057,2.057,0,0,1-.979.248H1.019A1.014,1.014,0,0,0,0,8.018v7.965A1.013,1.013,0,0,0,1.019,16.99H5.182a2.058,2.058,0,0,1,.979.248l6.33,3.427a1.026,1.026,0,0,0,1.384-.4A1,1,0,0,0,14,19.78V4.22a1.014,1.014,0,0,0-1.019-1.008ZM1.33,8.34H4.7v7.32H1.33ZM12.67,12v7.249L6.794,16.068a3.376,3.376,0,0,0-.768-.3V8.231a3.385,3.385,0,0,0,.768-.3L12.67,4.751ZM16.5,16.451l-.947-.934A5.33,5.33,0,0,0,16.991,12a5.289,5.289,0,0,0-1.439-3.52L16.5,7.54A6.7,6.7,0,0,1,18.322,12,6.625,6.625,0,0,1,16.5,16.451Z" transform="translate(1 -0.212)"/>-->
<!--	  </symbol>-->
<!--      <symbol id="player-volume-maximum-icon" viewBox="0 0 24 24">-->
<!--		  <path id="Combined-Shape" d="M12.981,3.212a1.03,1.03,0,0,0-.49.124L6.161,6.762a2.057,2.057,0,0,1-.979.248H1.019A1.014,1.014,0,0,0,0,8.018v7.965A1.013,1.013,0,0,0,1.019,16.99H5.182a2.058,2.058,0,0,1,.979.248l6.33,3.427a1.026,1.026,0,0,0,1.384-.4A1,1,0,0,0,14,19.78V4.22a1.014,1.014,0,0,0-1.019-1.008ZM1.33,8.34H4.7v7.32H1.33ZM12.67,12v7.249L6.794,16.068a3.376,3.376,0,0,0-.768-.3V8.231a3.385,3.385,0,0,0,.768-.3L12.67,4.751ZM16.5,16.451l-.947-.934A5.33,5.33,0,0,0,16.991,12a5.289,5.289,0,0,0-1.439-3.52L16.5,7.54A6.7,6.7,0,0,1,18.322,12,6.625,6.625,0,0,1,16.5,16.451Zm2.8,2.187-.947-.934a8.847,8.847,0,0,0,2.343-5.714,8.88,8.88,0,0,0-2.337-5.709l.941-.941a10.164,10.164,0,0,1,2.726,6.65,10.3,10.3,0,0,1-2.726,6.648Z" transform="translate(1 -0.212)"/>-->
<!--	  </symbol>-->
<!--      <symbol id="picture-icon" viewBox="0 0 24 24">-->
<!--          <path id="_24_Image" data-name="24 Image" d="M12,2.3A9.7,9.7,0,1,1,2.3,12,9.71,9.71,0,0,1,12,2.3M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm6.5,15.15H5.5a.65.65,0,0,1-.557-.984l3-5a.675.675,0,0,1,1.114,0l.985,1.64,2.907-4.651a.675.675,0,0,1,1.1,0l5,8a.651.651,0,0,1-.551.995ZM6.648,14.85H17.327L13.5,8.727,10.582,13.4a.65.65,0,0,1-1.108-.011L8.5,11.763ZM9.5,5.5a1,1,0,1,0,1,1A1,1,0,0,0,9.5,5.5Z" fill="#001e50"/>-->
<!--      </symbol>-->
<!--      <symbol id="search-icon"  viewBox="0 0 24 24">-->
<!--          <rect id="Rectangle_41" data-name="Rectangle 41" width="24" height="24" fill="#001e50" opacity="0"/>-->
<!--          <path id="Shape" d="M21.5,20.53,16.69,15.6a9.38,9.38,0,0,0,2-5.79A9.19,9.19,0,0,0,9.59.5,9.2,9.2,0,0,0,.5,9.81a9.2,9.2,0,0,0,9.09,9.31,8.94,8.94,0,0,0,6.19-2.51l4.77,4.89ZM1.84,9.81A7.85,7.85,0,0,1,9.59,1.88a7.85,7.85,0,0,1,7.74,7.93,7.85,7.85,0,0,1-7.74,7.94A7.86,7.86,0,0,1,1.84,9.81Z" transform="translate(1.501 1.5)" fill="#001e50"/>-->
<!--      </symbol>-->
<!--      <symbol id="quit-icon" viewBox="0 0 24 24">-->
<!--          <path id="Combined-Shape" d="M18.65,3.35A10.315,10.315,0,0,1,21.665,11,10.315,10.315,0,0,1,11,21.665,10.315,10.315,0,0,1,.335,11,10.315,10.315,0,0,1,11,.335,10.315,10.315,0,0,1,18.65,3.35ZM11,1.665A9.029,9.029,0,0,0,1.665,11,9.029,9.029,0,0,0,11,20.335,9.029,9.029,0,0,0,20.335,11,9.029,9.029,0,0,0,11,1.665ZM13.53,7.53l.94.941L11.941,11l2.529,2.53-.94.941L11,11.941l-2.53,2.53-.94-.941L10.059,11,7.53,8.471l.94-.941L11,10.059Z" transform="translate(0.671 0.671)" fill="#001e50"/>-->
<!--      </symbol>-->
<!--      <symbol id="<back-icon>" viewBox="0 0 24 24">-->
<!--          <path id="Path" d="M20,6.032H2.125c.969-1.017,2.662-2.7,4.764-4.582L6,.46C5.942.514,0,5.844,0,6.7c0,.877,5.94,6.236,6,6.29L6.89,12c-2.1-1.885-3.812-3.6-4.782-4.635H20Z" transform="translate(1.997 5.54)" fill="#001e50"/>-->
<!--      </symbol>-->
<!--      <symbol id="chevron-icon" viewBox="0 0 24 24">-->
<!--          <path id="chevron-down" d="M12,17.813a1.655,1.655,0,0,1-1.189-.5l-9.786-10,.95-.93,9.785,10a.34.34,0,0,0,.48,0l9.784-10,.951.93-9.785,10A1.652,1.652,0,0,1,12,17.813Z" transform="translate(-0.025 -0.386)" fill="#001e50"/>-->
<!--      </symbol>-->
   </svg>
</div>`;
